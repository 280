import { Autocomplete, DistanceMatrixService } from "@react-google-maps/api";
import * as React from "react";
import FindUsContext from "../../../data/Contexts/FindUsContext";
import { Form } from "react-bootstrap";

const parrallelArrays = (dataArray) => {
  const locationKeys = [];
  const locationPositions = [];

  dataArray.forEach((element) => {
    const locKey = element.key;
    const locPos = element.props.gLatLng;

    locationKeys.push(locKey);
    locationPositions.push(locPos);
  });

  return { keys: locationKeys, destinations: locationPositions };
};

const combineParallelArrays = (array1, label1, array2, label2) => {
  let resultArray = [];
  let tempobject = {};
  for (let i = 0; i < array1.length; i++) {
    tempobject = {
      key: array1[i],
      distance: { ...array2[i] },
    };
    resultArray.push(tempobject);
  }
  return resultArray;
};

export const LocationSearch = ({}) => {
  const autocompleteRef = React.useRef(null);
  const distanceMatrixRef = React.useRef(null);
  const pageContext = React.useContext(FindUsContext);
  const markers = pageContext.markerArray;
  const userLocation = pageContext.userLocation;
  const locationArrays = parrallelArrays(markers);
  const [search, setSearch] = React.useState(false);

  const [address, setAddress] = React.useState("");
  const [origin, setOrigin] = React.useState(userLocation ?? []);
  const options = {
    destinations: locationArrays.destinations,
    origins: [origin],
    travelMode: "DRIVING",
  };
  const types = ["address"];
  const fields = ["geometry", "formatted_address"];

  const distanceMatrixServiceCallback = async (userlocation) => {
    console.log("DistanceMartrix stuff p1");
    console.log(distanceMatrixRef.current);
    console.log(userLocation);
    if (distanceMatrixRef.current && userLocation) {
      console.log("HERE");
      const distances = [];
      if (options.destinations.length > 25) {
        let fullDestinations = options.destinations.slice();
        let tempOptions = { ...options };
        console.log("DistanceMartrix stuff: > 25");
        for (let i = 0; i < fullDestinations.length; i += 25) {
          const chunk = fullDestinations.slice(i, i + 25);
          tempOptions.destinations = chunk;
          // console.log(chunk);
          await distanceMatrixRef.current.getDistanceMatrix(
            tempOptions,
            (response, status) => {
              // console.log(tempOptions.destinations);
              // console.log(response);
              // console.log(status);
              if (status === "OK") {
                //  console.log(response.rows);
                distances.push(...response.rows[0].elements);
              }
            }
          );
        }
      } else {
        console.log("DistanceMartrix stuff < 25");
        distanceMatrixRef.current.getDistanceMatrix(
          options,
          (response, status) => {
            console.log(response);
            console.log(status);
            if (status === "OK") {
              console.log(response.rows);
            }
          }
        );
      }
      console.log("FINAL DISTANCES");
      setSearch(false);
      let finalDistances = combineParallelArrays(
        locationArrays.keys,
        "keys",
        distances,
        "distances"
      );
      //console.log(finalDistances);
      markers.forEach((marker) => {
        marker.props.details.distances = finalDistances.find(
          (x) => x.key === marker.key
        );
      });
      //let newMarkers = [...markers];
      console.log(markers);
      pageContext.setMarkerArray([...markers]);
    }
  };

  function callback(response, status, distances) {
    console.log(status);
    console.log(response);
  }

  const onDistanceMatrixLoad = (distanceMatrix) => {
    console.log("Distance MAtrix Loaded");
    distanceMatrixRef.current = distanceMatrix;
  };

  React.useEffect(() => {
    console.log("userLocation Changed");
  }, [userLocation]);

  React.useEffect(() => {
    console.log("origin Changed");
    setSearch(true);
    distanceMatrixServiceCallback(options, origin);
  }, [origin]);

  function onPlaceChanged() {
    if (autocompleteRef.current) {
      let place = autocompleteRef.current.getPlace();
      // let userGeometry = {
      //   lat: place.geometry.location.lat(),
      //   lng: place.geometry.location.lng(),
      // };
      let userGeometry = place.geometry.location;
      //console.log(userGeometry);
      pageContext.setUserLocation(userGeometry);
      setOrigin(userGeometry);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }
  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  return (
    <>
      {search && (
        <DistanceMatrixService
          callback={distanceMatrixServiceCallback}
          onLoad={onDistanceMatrixLoad}
          options={options}
        />
      )}
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        fields={fields}
        types={types}
        restrictions={{ country: "us" }}
      >
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            {/* <Form.Label>Email address</Form.Label> */}
            <Form.Control type="text" placeholder="Enter Your Address" />
          </Form.Group>
        </Form>
        {/* <input type="text" placeholder="Enter Your Address"></input> */}
      </Autocomplete>
    </>
  );
};

export default LocationSearch;
