export const primaryColors = {
    Blue:'#2B6BE4',
    Orange:'#F7971D',
    Chocolate: '#826762',
    Yellow: '#F7D650',
    LightBlue: '#8DD0DE'
}


export const flavorColors = {
    RaspberryLemon: '#E6697A',
    BerryCheesccake: '#2B6BE4',
    PineappleMangoChillSorbet: '#FAB317',
    MyGodfathersCannoli: '#CBB1A2',
    TipsyChimp:'#F9E168',
    WakeUpCall:'#472B15',
    CinnamonAppleALaMode: '#8A3D36',
    KentuckyMaplePecan: '#B56D35',
    ChunkyPBC:'#DDA565'
}