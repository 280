import * as React from "react";
import Container from "react-bootstrap/Container";
import { Image, Ratio } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { baseTheme } from "../../styles/themes/theme";
import styled from "@emotion/styled";
import ChalkBoard1 from "../../images/chalkboards/noita-digital-MdmcpX2ofRk-unsplash.jpg";
import ChalkBoard2 from "../../images/chalkboards/peter-gargiulo-cGNCepznaV8-unsplash.jpg";
import ChalkBoard3 from "../../images/chalkboards/tim-mossholder-_YwD-QZMW8c-unsplash.jpg";
import ChalkBoard4 from "../../images/chalkboards/empty-blackboard.jpg";
import OverHeadFlavors from "../../images/CHALKED IMAGES/Overhead chalked.jpg";
import { maxHeight } from "@mui/system";

const StyledDiv = styled(Container)(() => ({
  backgroundImage: `url(${ChalkBoard4})`,
  backgroundSize: "cover",
  backgroundColor: "black",
  "@media screen and (min-width: 768px)": {
    height: "600px",
  },
  height: "500px",
  paddingTop: "85px",
  // alignItems: 'center',
  // alignContent: 'center',
  // margin: 'auto',
  // display: 'flex'
}));

const StyledImageHero = styled(Image)(() => ({
  // margin: 'auto',
}));

const StyledHeroText = styled(`h1`)(() => ({
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.BertonVoyage,
  textAlign: "center",

  "@media screen and (min-width: 768px)": {
    fontSize: "100px",
  },
  fontWeight: "bold",
  fontSize: "50px",
}));
const HeroCol = styled(Col)(() => ({
  // margin: 'auto',
  // height:'auto',
  // maxHeight: 'inherit',
  // justifyContent: 'center',
  // alignItems: 'center'
}));
const StyledRow = styled(Row)(() => ({
  // justifyContent: 'center',
  // margin: 'auto',
  // alignItems: 'center',
  // display: 'flex'
}));

const HeroRatio = styled(Ratio)(() => ({
  height: "300px",
  "@media screen and (min-width: 768px)": {
    height: "500px",
  },
}));

const baseHeroImageStyle = {
  maxHeight: "1400px",
};

const baseTextStyle = {
  maxHeight: "1400px",
  color: "#fff",
};

const defaultHeroColumnBreakpoints = {
  xs: { span: 7, offset: 0 },
  xl: { span: 8, offset: 1 },
};
const defaultHeroImageBreakpoints = {
  xs: { span: 5, offset: 0 },
  xl: { span: 5, offset: 0 },
};

//Image Resize for Flavors page

export const Hero = ({
  heroText,
  heroImage,
  thumbnail,
  heroImageStyle,
  heroTextStyle,
  heroColumnBreakpoints,
  heroImageColumnBreakpoints,
}) => {
  if (heroImageStyle == null) {
    heroImageStyle = baseHeroImageStyle;
  }
  if (heroTextStyle == null) {
    heroTextStyle = baseTextStyle;
  }
  if (heroColumnBreakpoints == null) {
    heroColumnBreakpoints = defaultHeroColumnBreakpoints;
  }
  if (heroImageColumnBreakpoints == null) {
    heroImageColumnBreakpoints = defaultHeroImageBreakpoints;
  }

  return (
    <StyledDiv fluid className="">
      <Row className={"align-items-center gx-0"}>
        <HeroCol xs={heroColumnBreakpoints.xs} xl={heroColumnBreakpoints.xl}>
          <StyledHeroText className="" style={heroTextStyle}>
            {heroText.toUpperCase()}
          </StyledHeroText>
        </HeroCol>
        <HeroCol
          xs={heroImageColumnBreakpoints.xs}
          xl={heroImageColumnBreakpoints.xl}
          className={"float-end"}
        >
          <HeroRatio aspectRatio={50}>
            <StyledImageHero
              src={heroImage}
              thumbnail={thumbnail}
              style={heroImageStyle}
              className="d-block mx-auto img-fluid"
            />
          </HeroRatio>
        </HeroCol>
      </Row>
    </StyledDiv>
  );
};

export default Hero;
