import * as React from "react";
import { Box, Stack } from "@mui/system";
import { baseTheme } from "../../styles/themes/theme";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { styled } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid";
// import PrimaryLogo from '../../images/Primary_Logo.png';
import PrimaryLogo from "../../images/logos/Primary_Logo.png";
import { Ratio } from "react-bootstrap";
// import PrimaryLogo from '../../images/logos/SalesSheetLogo.jpg'
import EquationsBanner, {
  EquationsBannerMini,
} from "../features/EquationBanner";
import PrimaryLogo2 from "../../images/Primary_Logo.png";
import PB_Logo from "../../images/logos/slice1.png";
import FacebookIcon from "../socialMediaIcons/Facebook";
import InstagramIcon from "../socialMediaIcons/InstagramIcon";
import TwitterIcon from "../socialMediaIcons/TwitterIcon";

import { flavorColors, primaryColors } from "../../styles/themes/colors";
import { PrimaryButton } from "../styled/styledButtons";
import { StyledH1 } from "../styled/styledText";
import CurvedBorder, { CurvedBorderTop } from "./CurvedBorder";
import { NavLink } from "react-router-dom";
import TikTokIcon from "../socialMediaIcons/TikTokIcon";

const StyledSocialTag = styled(Button)(() => ({
  color: baseTheme.palette.black,
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "1.5rem",
  background: "none",
  border: "none",
}));

const StyledCol = styled(Col)(() => ({
  textAlign: "center",
  alignItems: "center",
}));

const StyledLogo = styled(Image)(() => ({
  height: "100%",
  width: "auto",
}));

const StyledFooterDiv = styled(Container)(() => ({
  backgroundColor: baseTheme.palette.primaryWhite,
  paddingTop: "300px",
  paddingBottom: "50px",
  "@media screen and (max-width:769px)": {
    paddingTop: "100px",
  },
  "@media screen and (min-width: 770px) and (max-width:1200px)": {
    paddingTop: "200px",
  },
}));
const StyledContactUs = styled("h1")(() => ({
  fontFamily: baseTheme.typography.h1Text,
  textAlign: "center",
  color: baseTheme.palette.black,
  textTransform: "uppercase",
  paddingTop: "10px",
}));

const ContactUsDiv = () => {
  return (
    <>
      <StyledContactUs>{"Contact Us"}</StyledContactUs>
      <PrimaryButton as={NavLink} to={"ContactUs"}>
        Contact Us
      </PrimaryButton>
    </>
  );
};

const SocialMediaFooterSection = () => {
  return (
    <>
      <Row style={{ padding: "5px" }}>
        <StyledCol>
          <FacebookIcon height={"50"} width={"50"} color={primaryColors.Blue} />
          <StyledSocialTag
            href="https://www.facebook.com/swizzled"
            target="_blank"
          >
            Swizzled Desserts
          </StyledSocialTag>
        </StyledCol>
      </Row>
      <Row style={{ padding: "5px" }}>
        <StyledCol>
          <TikTokIcon
            height={"50"}
            width={"50"}
            color={flavorColors.RaspberryLemon}
          />
          <StyledSocialTag
            href="https://www.tiktok.com/@swizzleddesserts"
            target="_blank"
            active={false}
          >
            swizzleddesserts
          </StyledSocialTag>
        </StyledCol>
      </Row>
      <Row style={{ padding: "5px" }}>
        <StyledCol>
          <TwitterIcon
            height={"50"}
            width={"50"}
            color={primaryColors.LightBlue}
          />
          <StyledSocialTag
            href="https://twitter.com/get_swizzled"
            target="_blank"
            active={false}
          >
            get_swizzled
          </StyledSocialTag>
        </StyledCol>
      </Row>
      <Row style={{ padding: "5px" }}>
        <StyledCol>
          <InstagramIcon
            height={"50"}
            width={"50"}
            color={primaryColors.Yellow}
          />
          <StyledSocialTag
            href="https://www.instagram.com/get.swizzled/"
            target="_blank"
            active={false}
          >
            get.swizzled
          </StyledSocialTag>
        </StyledCol>
      </Row>
    </>
  );
};

const Footer = () => {
  return (
    <>
      <Container
        fluid
        className={"gx-0"}
        style={{ padding: 0, margin: 0, position: "relative" }}
      >
        {/* <EquationsBannerMini/> */}
        <CurvedBorderTop
          backgroundColor={baseTheme.general.backgroundColor}
          innerBandColor={baseTheme.palette.tan}
          outerBandColor={baseTheme.palette.blue}
          top={0}
        />

        <StyledFooterDiv fluid>
          <Row className="">
            <StyledCol className="align-self-center">
              <ContactUsDiv />
            </StyledCol>

            <StyledCol md={{ span: 4 }} sm={{ span: 12, offset: 0 }}>
              <Ratio aspectRatio="16x9" height={"100%"} width={"auto"}>
                <StyledLogo src={PrimaryLogo2} />
              </Ratio>
            </StyledCol>

            <StyledCol className="align-self-center">
              <SocialMediaFooterSection />
            </StyledCol>
          </Row>
        </StyledFooterDiv>
      </Container>
    </>
  );
};

export default Footer;
