import React, { useEffect } from "react";
import { APIProvider, Map, useMap } from "@vis.gl/react-google-maps";
import mapStyle from "../../styles/mapStyle";
import TanBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Tan.png";
import BlackBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Black.png";
import WhiteBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_White.png";
import BlueBadge from "../../images/Badge_Logo_Blue.png";
import IconMarker from "./Markers/IconMarker";

import buildLocationArray from "../util/buildLocationArray";

import { LocationCategories } from "../../data/Constants/LocationCategories";
import { LocationIcons } from "../../data/Constants/LocationIcons";
import SwizzledInfoWindow from "./Markers/InfoWindows/InfoWindow";
import FindUsContext from "../../data/Contexts/FindUsContext";
const markerArrayFromLocationArray = (locationArray, onClick) => {
  const localOnClick = (e) => {
    // console.log("MarkerArrayFromLocationArray onClick");
    // console.log(e);
    if (onClick) {
      // console.log("MarkerArrayFromLocationArray onClick function there");
      onClick(e);
    }
  };
  var visibility = false;
  const markerArray = locationArray.map((location) => {
    let icon = null;
    switch (location.category) {
      case LocationCategories.events:
        icon = TanBadge;
        break;
      case LocationCategories.retailers:
        icon = BlackBadge;
        break;
      case LocationCategories.restaurants:
        icon = BlueBadge;
        break;
      case LocationCategories.iceCreamShops:
        icon = WhiteBadge;
        break;
      default:
        return WhiteBadge;
    }

    return (
      <IconMarker
        key={location.key}
        position={location.position}
        icon={LocationIcons[location.category]}
        locationDetails={location}
        visibility={visibility}
        onClick={localOnClick}
      />
    );
  });
  return markerArray;
};

export const SwizzledMapMarkers = ({ mapCallback, onCLickCallback }) => {
  const [renderedMarkers, setRenderedMarkers] = React.useState([]);
  const map = useMap();
  const data = buildLocationArray();
  const markers = markerArrayFromLocationArray(data, onCLickCallback);
  useEffect(() => {
    if (!map) return;
    if (mapCallback) {
      mapCallback(map);
    }
  }, [map]);

  return <>{markers}</>;
};

export const SwizzledMapComponent = () => {
  const defaultPosition = { lat: 40.7128, lng: -73.1006 };
  const defaultZoom = 8;
  const data = buildLocationArray();

  const [infoWindowShown, setInfoWindowShown] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const { selectedMarker, setSelectedMarker } = React.useContext(FindUsContext);
  const [map, setMap] = React.useState();
  const markerClicked = (e) => {
    // if the marker clicked is the same as the anchor, close the info window and clear the anchor

    if (anchor === e) {
      // console.log("Anchor === e");
      setAnchor(null);
      setInfoWindowShown(false);
      setSelectedMarker(null);
      return;
    }
    // console.log("M");
    // console.log(e);
    setAnchor(e);
    setSelectedMarker(e.key);
    setInfoWindowShown(true);
  };
  const markers = markerArrayFromLocationArray(data, markerClicked);

  // useEffect(() => {
  //   if (!map) return;
  //   console.log("MAP IS RENDERED");
  // }, [map]);

  React.useEffect(() => {
    console.log("SwizzledMapComponent useEffect");
    console.log(selectedMarker);
    console.log(markers);
    if (selectedMarker) {
      setAnchor(null);
      setInfoWindowShown(false);
      const marker = markers.find(
        (item) => item.props.locationDetails.key === selectedMarker
      );

      if (marker && map) {
        markerClicked(marker.props);
        setAnchor(marker.props);
        map.panTo(marker.props.locationDetails.position);
        map.setZoom(16);
      }
    }
  }, [selectedMarker]);

  // when a marker is clicked, the anchor is set to the marker that was clicked
  // and the info window is shown

  const closeInfoWindow = () => {
    // console.log("Close Info Window");
    setAnchor(null);
    setInfoWindowShown(false);
    if (map) {
      map.setZoom(8);
      map.panTo(defaultPosition);
    }
  };

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <Map
        style={{ height: "600px", width: "100%" }}
        defaultCenter={defaultPosition}
        defaultZoom={defaultZoom}
        disableDefaultUI={true}
        options={mapStyle}
      >
        <SwizzledMapMarkers
          mapCallback={setMap}
          onCLickCallback={markerClicked}
        />
        {/* {markers} */}
        {infoWindowShown && (
          <SwizzledInfoWindow
            position={anchor.position}
            //anchor={anchor}
            onCloseClick={closeInfoWindow}
            locationDetails={anchor.locationDetails}
          />
        )}
      </Map>
    </APIProvider>
  );
};

export default SwizzledMapComponent;
