import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SwizzledMap from "../components/features/Map2";
import styled from "@emotion/styled";
import StyledParagraph, { StyledH1 } from "../components/styled/styledText";

import {
  Accordion,
  Button,
  ListGroup,
  ListGroupItem,
  Ratio,
} from "react-bootstrap";
import ContainersStacked from "../images/CHALKED IMAGES/edited/stacked.png";
import ContainersStacked2 from "../images/finduspage/16x9.png";
import ContainersStacked2wBackground from "../images/finduspage/16x9wBackground.png";
import Banner, {
  BannerwithImage,
  BannerwithImageFirst,
} from "../components/features/Banner";
import TableCard from "../images/01_Restaurant_Table_Tent_Template.jpg";
import RestaurantList from "../data/Resturants";
import RetailersList, { retailCount } from "../data/Retailers";
import EventsList from "../data/Events";
import { PrimaryButton } from "../components/styled/styledButtons";
import EventCalendar from "../components/features/Calendar";
import MapImg from "../images/CHALKED IMAGES/edited/Map.png";
import LatestEventsSection from "../components/features/EventsSection";
import MapImg2 from "../images/Find Us Page - Header Photo.jpg";
import MapImg3 from "../images/finduspage/Find Us Page - Header Photo.jpg";
import { baseTheme } from "../styles/themes/theme";
import { flavorColors, primaryColors } from "../styles/themes/colors";
import RestaturantCard from "../images/aboutpage/restarauntCard.jpg";
import FacebookIcon from "../components/socialMediaIcons/Facebook";
import InstagramIcon from "../components/socialMediaIcons/InstagramIcon";
import TwitterIcon from "../components/socialMediaIcons/TwitterIcon";
import Hero2 from "../components/features/Hero2";
import LocationCard, {
  MultipleLocationCard,
} from "../components/features/LocationCard";
import TikTokIcon from "../components/socialMediaIcons/TikTokIcon";
import FilterButtons from "../components/features/StoreFinders/FilterButtons";
import LocationSearch from "../components/features/StoreFinders/LocationSearch";
import ListGroupDisplayOLD from "../components/features/LocationDisplays/ListGroupDisplayOLD";
import FindUsContext from "../data/Contexts/FindUsContext";
import { useJsApiLoader } from "@react-google-maps/api";
import buildLocationArray from "../components/util/buildLocationArray";
import IceCreamShops from "../data/IceCreamShops";

const partnerTitle = "Want to be a partner?";
const specialOfferTitle = "A SPECIAL OFFER";
const text1 =
  "While ambitious for brand success, we are being careful as to not exceed our small batch manufacturing model and are focused on local distribution. As our partner group grows across the Island, they will be featured and added to the site. Look for pint sized Swizzled desserts on Long Island and Westchester in specitalty food stores and grocers.";
const text2 =
  "Additionally, watch for Swizzled adding some buzz to dessert menus at some of your favorite restaurants as well as pop up events across Long Island.";
const text3 =
  "If you are thrilled with your Swizzled experience, please tell all your friends, family and co-workers. Hell, tell the guy in the car next to you at a light and the lady in front of you getting coffee for that matter. Follow us on Facebook, Twitter and Instagram.";
const text4 =
  "But to help us grow and make sure that you never run out of Swizzled, WE’RE MAKING YOU A SPECIAL OFFER. Send us an email with a local retailer or serving establishment that you would like to see sell our brand along with the name of the contact you spoke with. If we partner with them to promote Swizzled desserts, we’ll send you a free 4-pack of your favorite flavors. We’ll promise you that for as many locations as you introduce us to.";
const partnerText =
  "We have an excellent partnership program. If you are interested in becoming a retailer or serving establishment of Swizzled products, please reach out to us and we will be happy to provide information and pricing. ";

const StyledCol = styled(Col)(() => ({
  margin: "auto",
  textAlign: "center",
  padding: "5px",
}));

const Divider = styled("hr")(() => ({
  margin: "2rem 0",
}));

const StyledColJustified = styled(Col)(() => ({
  textAlign: "justify",
  alignContent: "center",
  alignItems: "center",
}));

const StyledSocialTag = styled(Button)(() => ({
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "2rem",
  background: "none",
  border: "none",
  "@media screen and (max-width:769px)": {
    fontSize: "1rem",
  },
}));

const IconStyle = {
  height: "80px",
  width: "80px",
};

const LoveSwizzledSection = () => {
  return (
    <>
      <Row>
        <StyledCol>
          <StyledH1 text="Love Swizzled?!?!?" />
        </StyledCol>
      </Row>
      <Row>
        {/* <Row> */}
        <StyledCol
          color={"#ffffff"}
          sm={{ span: 12, order: 2 }}
          lg={{ span: 5, order: 1 }}
        >
          <Row>
            <StyledCol>
              <FacebookIcon style={IconStyle} color={primaryColors.Blue} />
              <StyledSocialTag
                as={"a"}
                href="https://www.facebook.com/swizzled"
                target="_blank"
              >
                Swizzled Desserts
              </StyledSocialTag>
            </StyledCol>
          </Row>
          <Row>
            <StyledCol>
              <TikTokIcon color={flavorColors.RaspberryLemon} />
              <StyledSocialTag
                as={"a"}
                href="https://www.tiktok.com/@swizzleddesserts"
                target="_blank"
              >
                swizzleddesserts
              </StyledSocialTag>
            </StyledCol>
          </Row>
          <Row>
            <StyledCol>
              <TwitterIcon color={primaryColors.LightBlue} />
              <StyledSocialTag
                as={"a"}
                href="https://twitter.com/get_swizzled"
                target="_blank"
              >
                get_swizzled
              </StyledSocialTag>
            </StyledCol>
          </Row>
          <Row>
            <StyledCol>
              <InstagramIcon color={primaryColors.Yellow} />
              <StyledSocialTag
                as={"a"}
                href="https://www.instagram.com/get.swizzled/"
                target="_blank"
              >
                get.swizzled
              </StyledSocialTag>
            </StyledCol>
          </Row>
        </StyledCol>
        <StyledColJustified
          className="align-self-center"
          sm={{ span: 12, order: 1 }}
          lg={{ span: 7, order: 2 }}
        >
          <StyledParagraph text={text3} />
        </StyledColJustified>
        {/* </Row> */}
        {/* </StyledCol> */}
      </Row>
    </>
  );
};

const BecomeAPartnerSection = () => {
  return (
    <>
      <BannerwithImage
        bannerText={partnerText}
        bannerTitle={partnerTitle}
        bannerImg={RestaturantCard}
        bannerLinkText="Become a Partner!!"
        bannerLink="/ContactUs"
      />
      <Divider />
    </>
  );
};

const SpecialOfferSection = () => {
  return (
    <>
      <BannerwithImageFirst
        bannerText={text4}
        bannerTitle={specialOfferTitle}
        bannerImg={ContainersStacked2wBackground}
        bannerLinkText="Offer"
        bannerLink="/ContactUs"
      />

      <Divider />
    </>
  );
};
const Libraries = ["places", "geometry"];
export const FindUsPage2 = () => {
  const categoryCounts = {
    restaurants: RestaurantList.length ?? 0,
    events: EventsList.length ?? 0,
    retailers: retailCount ?? 0,
    iceCreamShops: IceCreamShops.length ?? 0,
  };
  const [categories, setCategories] = React.useState({
    events: true,
    retailers: true,
    restaurants: true,
    iceCreamShops: true,
  });
  const [searchTerm, setSearchTerm] = React.useState();
  const [selectedItem, setSelectedItem] = React.useState();
  const [markers, setMarkers] = React.useState();
  const [mapLoaded, setMapLoaded] = React.useState();
  const [userLocation, setUserLocation] = React.useState();
  const [locations, setLocations] = React.useState(
    buildLocationArray(RestaurantList, EventsList, RetailersList)
  );
  const [distances, setDistances] = React.useState();

  const selectedItemCallback = (val) => {
    setSelectedItem(val);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB4oGcmRiH_SagxqDTXGPKIk5XmfeKLnyE",
    libraries: Libraries,
  });
  React.useEffect(() => {
    if (isLoaded) {
      setMapLoaded(true);
    }
  }, [isLoaded]);
  React.useEffect(() => {
    console.log("selectedItem - findusPage");
    console.log(selectedItem);
    console.log("*******************************");
  }, [selectedItem]);

  React.useEffect(() => {
    console.log("markers - findusPage");
    console.log(markers);
    // console.log(locations);
    console.log("*******************************");
  }, [markers]);

  return (
    <>
      <FindUsContext.Provider
        value={{
          selectedMarker: selectedItem,
          setSelectedMarker: setSelectedItem,
          markerArray: markers,
          setMarkerArray: setMarkers,
          categories: categories,
          setCategories: setCategories,
          userLocation: userLocation,
          setUserLocation: setUserLocation,
          locationsArray: locations,
          setLocationsArray: setLocations,
          distances: distances,
          setDistances: setDistances,
        }}
      >
        <Hero2 heroText={"Find Us"} />

        <Container>
          <Divider style={{ border: 0 }} />
          <LoveSwizzledSection />
          <Divider />
          <div>
            <Banner>
              <StyledH1 text="Calendar" />
            </Banner>
          </div>
          <Divider />
          <Row>
            <StyledCol>
              <StyledH1 text="Locations" />
            </StyledCol>
          </Row>
          <Row>
            <Col>
              <StyledParagraph text={text1} />
            </Col>
          </Row>
          <Row>
            <Col>
              <StyledParagraph text={text2} />
            </Col>
          </Row>
          <Row>
            <Col
              sm={{ span: 8, order: 1 }}
              lg={{ span: 4, order: 1 }}
              // style={{ background: "white" }}
            >
              {mapLoaded && (
                <Row>
                  {" "}
                  <LocationSearch />
                </Row>
              )}
              <Row>
                <div>
                  <FilterButtons
                    categories={categories}
                    setCategories={(val) => setCategories(val)}
                    counts={categoryCounts}
                  />
                </div>
              </Row>
              <Row>
                <Col
                  style={{
                    maxHeight: "500px",
                    overflow: "auto",
                  }}
                >
                  <ListGroup>
                    {categories.restaurants && (
                      <ListGroupDisplayOLD
                        objArr={RestaurantList}
                        type={"restaurant"}
                        selectedItemCallback={(val) => setSelectedItem(val)}
                      />
                    )}
                    {categories.events && (
                      <ListGroupDisplayOLD
                        objArr={EventsList}
                        type={"event"}
                        selectedItemCallback={(val) => setSelectedItem(val)}
                      />
                    )}
                    {categories.retailers && (
                      <ListGroupDisplayOLD
                        objArr={RetailersList}
                        type={"retailer"}
                        selectedItemCallback={(val) => setSelectedItem(val)}
                      />
                    )}
                    {categories.iceCreamShops && (
                      <ListGroupDisplayOLD
                        objArr={IceCreamShops}
                        type={"iceCreamShop"}
                        selectedItemCallback={(val) => setSelectedItem(val)}
                      />
                    )}
                  </ListGroup>
                </Col>
              </Row>
            </Col>
            <StyledCol sm={{ span: 12, order: 1 }} lg={{ span: 8, order: 1 }}>
              <SwizzledMap
                categories={categories}
                selectedKey={selectedItem}
                isLoadedParent={isLoaded}
              />
            </StyledCol>
          </Row>
          <Divider />
          <SpecialOfferSection />

          <BecomeAPartnerSection />
        </Container>
      </FindUsContext.Provider>
    </>
  );
};

export default FindUsPage2;
