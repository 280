import React from "react";
import { Card, Container, Fade, Image, Ratio } from "react-bootstrap";

import styled from "@emotion/styled";
import { baseTheme } from "../../../styles/themes/theme";
import DietaryBadges from "../DietaryBadge";
import { display, height, width } from "@mui/system";

const ProductCard = styled(Card)(() => ({
  backgroundColor: baseTheme.general.backgroundColor,
  minHeight: "100%",
}));
const ProductImageDiv = styled(Card.Img)(() => ({
  borderRadius: "10px",
  display: "block",
}));

const ProductDiv = styled(Container)({
  borderRadius: "10px",
  overflow: "hidden",
  position: "relative",
});
const ProductTitle = styled(Card.Header)(() => ({
  color: "white",
  textAlign: "center",
  borderRadius: "10px",
}));
const ProductDesc = styled(Card.Text)(() => ({
  textAlign: "center",
  color: "white",
}));

const ProdImageOverlay = styled(Card.ImgOverlay)(() => ({
  // add gradient overlay
  borderRadius: "10px",
}));

export const FlavorCard = ({ flavorObject }) => {
  //console.log(flavorObject);
  const [hovered, setHovered] = React.useState(false);
  return (
    <Container>
      <ProductCard
        className="border-0"
        border="light"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Fade style={{ position: "absolute", height: "auto" }} in={!hovered}>
          <>
            <div style={{ height: "60px", margin: "auto" }}>
              <DietaryBadges flavorObject={flavorObject} />
            </div>
            <Card.Body className="text-light">
              <ProductImageDiv src={flavorObject.pintImg} />
            </Card.Body>
          </>
        </Fade>

        <Fade in={hovered} style={{ position: "absolute" }}>
          <Container>
            <ProductImageDiv src={flavorObject.ingredientsImg} />

            <ProdImageOverlay>
              <Container>
                <ProductTitle>{flavorObject.Title}</ProductTitle>
                <ProductDesc>{flavorObject.flavorDescr}</ProductDesc>

                <DietaryBadges
                  style={{
                    position: "absolute",
                    bottom: "22%",
                    "@media (maxWidth: 768px)": {
                      bottom: "35%",
                    },
                  }}
                  flavorObject={flavorObject}
                />
              </Container>
            </ProdImageOverlay>
          </Container>
        </Fade>
        {/* <ProductImageDiv
          src={hovered ? flavorObject.ingredientsImg : flavorObject.pintImg}
          className=" object-fit-cover"
        /> */}
      </ProductCard>
    </Container>
  );
};

export default FlavorCard;
