import React from "react";
import { StyledContainer } from "../components/features/Hero2";
import SwizzledMapComponent from "../components/features/Map3";
import { Container } from "react-bootstrap";
import Hero2 from "../components/features/Hero2";
import { Grid, Row, Col } from "react-bootstrap";
export const Recipes = () => {
  return (
    <>
      <Hero2 heroText={"Recipes"} />
      <Row>
        <Col>
          <h1>Recipes</h1>
        </Col>
      </Row>
      <Row></Row>
    </>
  );
};

export default Recipes;
