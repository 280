import * as React from 'react';
import emailjs from '@emailjs/browser';

function submitEmail(firstName, lastName,submittersEmail, submittersMessage){
return(new Promise(resolve=>{
    // setTimeout(() => {
    //     resolve('error');
    //   }, 2000);
    console.log("submitting Email");

    let templateParams = {
        fname: firstName,
        lname: lastName,
        reply_to: submittersEmail,
        message: submittersMessage
    }
    console.log(templateParams);

    emailjs.send("service_t7l153g","template_tgpw4o2", templateParams, "acruNtQHQ6Mga5pVv")
    .then((result)=>{
        console.log(result.text);
        resolve("good");
    }, (error)=>{
        console.log(error.text);
        resolve("error");
    }); 
}));
}


export default submitEmail;