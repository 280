import * as React from "react";
import Container from "react-bootstrap/Container";
import { Image, Ratio } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { baseTheme } from "../../styles/themes/theme";
import styled from "@emotion/styled";
import HeroImage from "../../images/Badge_Logo_Blue.png";
import HomePageHeroImage from "../../images/homepage/4x3.png";
import ChalkBoard1 from "../../images/chalkboards/noita-digital-MdmcpX2ofRk-unsplash.jpg";
import ChalkBoard2 from "../../images/chalkboards/peter-gargiulo-cGNCepznaV8-unsplash.jpg";
import ChalkBoard3 from "../../images/chalkboards/tim-mossholder-_YwD-QZMW8c-unsplash.jpg";
import ChalkBoard4 from "../../images/chalkboards/empty-blackboard.jpg";
import OverHeadFlavors from "../../images/CHALKED IMAGES/Overhead chalked.jpg";
import { maxHeight } from "@mui/system";
import CurvedBorder from "../layouts/CurvedBorder";

const StyledDiv = styled(Container)(() => ({
  backgroundColor: "#f2f2f2",
  paddingTop: "150px",
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: "0px",
  position: "relative",
}));

const StyledHeroImage = styled(Image)(() => ({
  display: "block",
  "@media screen and (max-width:769px)": {
    height: "120px",
    width: "120px",
  },
  height: "150px",
  width: "150px",
}));

const StyledHeroText = styled(`h1`)(() => ({
  color: baseTheme.palette.Black,
  fontFamily: baseTheme.typography.h1Text,
  textAlign: "end",
  marginTop: 0,
  fontWeight: "regular",
  "@media screen and (max-width:769px)": {
    fontSize: "4rem",
  },
  fontSize: "5rem",
}));

export const StyledContainer = styled(Container)(() => ({
  height: "600px",
  "@media screen and (max-width:769px)": {
    height: "250px",
  },
  "@media screen and (min-width: 770px) and (max-width:1200px)": {
    height: "300px",
  },
  "@media screen and (min-width: 1200.1px) and (max-width:1920px)": {
    height: "350px",
  },
}));

//Image Resize for Flavors page

export const Hero2 = ({ heroText, heroTextStyles }) => {
  return (
    // <Container fluid style={{backgroundColor: '#f2f2f2', marginBottom: '100px'}}>
    <StyledDiv fluid>
      <StyledContainer fluid>
        <Row>
          <Col xs={6} lg={7} className={"align-self-center"}>
            <StyledHeroText>{heroText}</StyledHeroText>
          </Col>
          <Col>
            <StyledHeroImage src={HeroImage} />
          </Col>
        </Row>
      </StyledContainer>
      <CurvedBorder
        backgroundColor={baseTheme.general.backgroundColor}
        innerBandColor={baseTheme.palette.tan}
        outerBandColor={baseTheme.palette.blue}
        bottom={0}
      />
    </StyledDiv>
    // </Container>
  );
};

const DivHomePage = styled(Container)(() => ({
  backgroundColor: "#f2f2f2",
  paddingTop: "150px",
  paddingLeft: 0,
  paddingRight: 0,
  marginBottom: "0px",
  position: "relative",
}));

const StyledHomePageHeroImage = styled(Image)(() => ({
  display: "block",
  float: "right",
  "@media screen and (max-width: 575.98px)": {
    height: "250px",
    width: "auto",
  },
  "@media screen and (min-width: 576px) and (max-width:769px)": {
    height: "250px",
    width: "auto",
  },
  "@media screen and (min-width: 770px) and  (max-width:1200px)": {
    height: "auto",
    width: "350px",
  },
  height: "auto",
  width: "400px",
}));
const HomePageHeroText = styled(`h1`)(() => ({
  color: baseTheme.palette.Black,
  fontFamily: baseTheme.typography.h1Text,
  textAlign: "end",
  marginTop: 0,
  fontWeight: "bold",
  fontStyle: "italic",
  "@media screen and (max-width:769px)": {
    fontSize: "2.5rem",
    textAlign: "center",
  },
  "@media screen and (min-width: 770px) and (max-width:1200px)": {
    fontSize: "3rem",
  },
  fontSize: "3.8rem",
}));
export const HomePageHero = ({ heroText, heroText2 }) => {
  return (
    // <Container fluid style={{backgroundColor: '#f2f2f2', marginBottom: '100px'}}>
    <DivHomePage fluid>
      <Container className={"clearfix"} style={{ height: 500 }} fluid>
        <Row>
          <Col xs={12} md={5} lg={7} className={"align-self-center"}>
            <HomePageHeroText>{heroText}</HomePageHeroText>
            <HomePageHeroText>{heroText2}</HomePageHeroText>
          </Col>
          <Col className="float-end">
            <StyledHomePageHeroImage src={HomePageHeroImage} />
          </Col>
        </Row>
      </Container>
      <CurvedBorder
        backgroundColor={baseTheme.general.backgroundColor}
        innerBandColor={baseTheme.palette.tan}
        outerBandColor={baseTheme.palette.blue}
        bottom={0}
      />
    </DivHomePage>
    // </Container>
  );
};

export default Hero2;
