import * as React from "react";

const FindUsContext = React.createContext({
  selectedMarker: null,
  setSelectedMarker: () => {},
  markerArray: null,
  setMarkerArray: () => {},
  locationsArray: null,
  setLocationsArray: () => {},
  categories: null,
  setCategories: () => {},
  userLocation: null,
  setUserLocation: () => {},
});

export default FindUsContext;
