// import { Container } from "@mui/system";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Hero from "../components/features/Hero";
import Header from "../components/layouts/header";
import styled from "@emotion/styled";
import Ratio from "react-bootstrap/Ratio";
import { Alert, Button } from "react-bootstrap";
import StyledParagraph, { StyledH1 } from "../components/styled/styledText";
import Operator from "../images/CHALKED IMAGES/Chalked Switchboard Operator.jpg";
import BlackContainer from "../images/contactpage/Webstaurant Black Catering Container.jpg";
import BlackContainer2 from "../images/contactpage/BlackContiner 1x1.jpg";
import Truck from "../images/contactpage/Scanned Ford Retouched.jpg";
import Truck2 from "../images/contactpage/ScannedFordRetouched16x9.jpg";
import Cart from "../images/contactpage/SwizzledCart2.jpeg";
import {
  PrimaryButton,
  StyledButton,
} from "../components/styled/styledButtons";
import Operator2 from "../images/contactpage/Chalked Switchboard Operator 2.jpg";
import { maxWidth } from "@mui/system";
import { baseTheme } from "../styles/themes/theme";
import submitEmail from "../components/emailCompoennt";
import Hero2 from "../components/features/Hero2";

const bulkText =
  "We offer on-site catering and serving provided in 2 different ways. We can provide our 2 gallon buckets of Swizzled boozy sorbets and ice creams in our sub-zero insulated tubs to help keep the chill. Your guests can enjoy a buzz while getting a brain freeze.";
const eventsText =
  "Or give your party a fun vintage feel by booking our custom Swizzledmobile! We’ll come to you with a selection of flavors and provide a server for your guests. You can kick back & relax while we do all the scooping! (This also makes for unique photo opportunities! 😉 )";
const cartText =
  "In addition to our insulated tubs, we also offer the option for our Swizzled Boozy Ice Cream Cart to be on site at your next event! Either option is a great way for you and your guests to enjoy a selection of Swizzled flavors and to kick back and relax while we do all the scooping! Contact us for rates and availability.";

const StyledImage = styled(Image)(() => ({
  maxHeight: "500px",
  maxWidth: "500px",
}));

const Divider = styled("hr")(() => ({
  margin: "2rem 0",
}));

const StyledCol = styled(Col)(() => ({
  margin: "auto",
  textAlign: "center",
  padding: "5px",
}));

const CateringAndEventsSection = () => {
  return (
    <>
      <Container>
        <Divider />
        <Row>
          <StyledCol>
            <StyledH1 text="Catering and Events" />
            {/* <StyledParagraph text="Want to get Swizzled at your next event? Whether held at a venue or private location, we can help bring the buzz to the party."/> */}
          </StyledCol>
        </Row>
        <Row>
          <StyledCol sm={{ span: 10, order: 1 }} md={{ span: 7, order: 1 }}>
            <StyledParagraph text="Want to get Swizzled at your next event? Whether held at a venue or private location, we can help bring the buzz to the party." />
            <StyledParagraph text={bulkText} />
          </StyledCol>
          <Col sm={{ span: 10, offset: -12 }} md={{ span: 5, order: 2 }}>
            <Ratio aspectRatio="1x1">
              <StyledImage src={BlackContainer2} rounded fluid />
            </Ratio>
          </Col>
        </Row>
        {/* <Row>
        <StyledCol sm={{span:12, order:1}} md={{span:7, order:2}}>
            <StyledParagraph text={eventsText}/></StyledCol>
          <Col sm={{span:12, order:2}} md={{span:5, order: 1}}>
          <Ratio aspectRatio="16x9">
              <Image src={Truck2}  rounded fluid/>
            </Ratio>
          </Col>
  
          </Row> */}
        <Row>
          <StyledCol sm={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }}>
            <StyledParagraph text={cartText} />
          </StyledCol>
          <Col sm={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }}>
            <Ratio aspectRatio="1x1">
              <Image src={Cart} rounded fluid />
            </Ratio>
          </Col>
        </Row>
        <Divider style={{ border: 0 }} />
      </Container>
    </>
  );
};

const ContactForm = () => {
  const [firstName, setFirstName] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [emailAddressError, setEmailAddressError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [messageError, setMessageError] = React.useState("");
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);

  function validateInputs() {
    let valid = true;
    if (firstName == "") {
      setFirstNameError("Please include your first name");
      valid = false;
    } else {
      setFirstNameError("");
    }
    if (lastName == "") {
      setLastNameError("Please inlcude your last name");
      valid = false;
    } else {
      setLastNameError("");
    }
    if (emailAddress == "") {
      setEmailAddressError("Please set your name");
      valid = false;
    } else {
      setEmailAddressError("");
    }
    if (message == "") {
      setMessageError("Please set your name");
      valid = false;
    } else {
      setMessageError("");
    }
    return valid;
  }

  function showEmailResponse(res) {
    if (res != null && res == "error") {
      setShowErrorAlert(true);
    } else if (res != null) {
      setShowSuccessAlert(true);
    }
  }

  async function handleSubmit() {
    console.log("submitClicked");
    let valid = validateInputs();
    let emailRes = null;
    if (valid) {
      emailRes = await submitEmail(firstName, lastName, emailAddress, message);
      showEmailResponse(emailRes);
      console.log(emailRes);
    } else {
      console.log("Invalid");
      setShowErrorAlert(true);
    }
  }

  return (
    <>
      {showErrorAlert ? (
        <Container>
          <Alert
            variant="danger"
            onClose={() => setShowErrorAlert(false)}
            dismissible
          >
            <Alert.Heading>Looks Like something Went Wrong</Alert.Heading>
            <p>Close this window and try again</p>
          </Alert>
        </Container>
      ) : (
        <></>
      )}

      {showSuccessAlert ? (
        <Container>
          <Alert
            variant="success"
            onClose={() => setShowSuccessAlert(false)}
            dismissible
          >
            <Alert.Heading>Thanks for the interest.</Alert.Heading>
            <p> We'll get back to you soon.</p>
          </Alert>
        </Container>
      ) : (
        <></>
      )}
      <Container>
        <Row>
          <StyledCol>
            <StyledH1 text="Contact Us" />
            {/* <StyledParagraph text="Interested in carrying swizzled? Let us know below!!!"/> */}
          </StyledCol>
        </Row>
        <Row>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="contactForm.firstName">
                  <Form.Label style={{ color: baseTheme.palette.primaryWhite }}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    placeholder="Jane"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="contactForm.lastName">
                  <Form.Label style={{ color: baseTheme.palette.primaryWhite }}>
                    Last Name
                  </Form.Label>
                  <Form.Control
                    placeholder="Doe"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group>
                <Form.Label style={{ color: baseTheme.palette.primaryWhite }}>
                  Email:
                </Form.Label>
                <Form.Control
                  placeholder="LoveSwizzled@example.com"
                  value={emailAddress}
                  onChange={(event) => setEmailAddress(event.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label style={{ color: baseTheme.palette.primaryWhite }}>
                  Your Message
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
              </Form.Group>
            </Row>
            <StyledCol>
              <StyledButton variant="primary" onClick={handleSubmit}>
                Submit
              </StyledButton>
            </StyledCol>
          </Form>
        </Row>
      </Container>
    </>
  );
};

export const ContactPage = () => {
  const overideForHeroColumnBreakpoints = {
    xs: { span: 10, offset: 1 },
    xl: { span: 5, offset: 2 },
  };
  const overideForHeroImageColumnBreakpoints = {
    xs: { span: 12, offset: 0 },
    xl: { span: 5, offset: 0 },
  };

  return (
    <>
      <Hero2 heroText={"Contact Us"} />
      <Divider style={{ border: 0 }} />
      <ContactForm />

      <CateringAndEventsSection />
    </>
  );
};

export default ContactPage;
