import * as React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./Footer";
import { baseTheme } from "../../styles/themes/theme";
import styled from "@emotion/styled";
import { EquationsBannerMini } from "../features/EquationBanner";

const Layout = ({ pageTitle, children }) => {
  return (
    <>
      <Container
        style={{
          backgroundColor: baseTheme.general.backgroundColor,
          padding: 0,
        }}
        fluid
      >
        <Header />
        <Outlet />
        {/* <EquationsBannerMini /> */}
        <Footer />
      </Container>
    </>
  );
};

export default Layout;
