import { LocationCategories } from "../../data/Constants/LocationCategories";
import IceCreamShops from "../../data/IceCreamShops";
import RestaurantList from "../../data/Resturants";
import { LocationIcons } from "../../data/Constants/LocationIcons";
import EventsList from "../../data/Events";
import RetailersList from "../../data/Retailers";
const buildTempObject = (
  obj,
  category,
  key,
  index,
  rName,
  index2 = null,
  icon = null
) => {
  return {
    key: key + index + (index2 != null ? "-" + index2 : ""),
    name: rName ? rName : obj.Title ? obj.Title : obj.name,
    altName: obj.altName ? obj.altName : null,
    address: {
      street: obj.Address ? obj.Address.street : obj.address.street,
      city: obj.Address ? obj.Address.city : obj.address.city,
      state: obj.Address ? obj.Address.state : obj.address.state,
      zipCode: obj.Address ? obj.Address.zipcode : obj.address.zipcode,
    },
    phoneNumber: obj.tel ? obj.tel : null,
    position: obj.latLng ? obj.latLng : obj.position,
    category: category,

    site: obj.site ? obj.site : obj.Site ? obj.Site : null,
    eventDetails: {
      time: obj.Time ? obj.Time : null,
      date: obj.Date ? obj.Date : null,
      startTime: obj.StartTime ? obj.StartTime : null,
      endTime: obj.EndTime ? obj.EndTime : null,
      location: obj.Address ? obj.Address.location : rName ? obj.name : null,
    },
    icon: icon,
  };
};

export const buildLocationArray = (restaurants, events, retailers) => {
  let resultArray = [];
  let tempObject = {};

  RestaurantList.forEach((rest, index) => {
    tempObject = buildTempObject(
      rest,
      LocationCategories.restaurants,
      "rest-",
      index,
      null,
      null,
      LocationIcons.restaurants
    );
    resultArray.push(tempObject);
  });
  EventsList.forEach((event, index) => {
    // if the event is in the past, don't include it
    const now = new Date();
    const eventDate = new Date(event.Date);
    if (eventDate < now) {
      return;
    }

    tempObject = buildTempObject(
      event,
      LocationCategories.events,
      "events-",
      index,
      null,
      null,
      LocationIcons.events
    );
    resultArray.push(tempObject);
  });
  IceCreamShops.forEach((shop, index) => {
    tempObject = buildTempObject(
      shop,
      LocationCategories.iceCreamShops,
      "ice-",
      index,
      null,
      null,
      LocationIcons.iceCreamShops
    );
    resultArray.push(tempObject);
  });
  RetailersList.forEach((retailer, index) => {
    if (retailer.multipleLocations) {
      retailer.locations.forEach((ret, index2) => {
        tempObject = buildTempObject(
          ret,
          LocationCategories.retailers,
          "retail-",
          index,
          retailer.name,
          index2,
          LocationIcons.retailers
        );
        resultArray.push(tempObject);
      });
    } else {
      tempObject = buildTempObject(
        retailer,
        LocationCategories.retailers,
        "retail-",
        index,
        null,
        null,
        LocationIcons.retailers
      );
      resultArray.push(tempObject);
    }
  });

  resultArray = resultArray.sort((a, b) => {
    if (
      a.category === LocationCategories.events &&
      b.category !== LocationCategories.events
    ) {
      return -1;
    }
    if (
      a.category === LocationCategories.events &&
      b.category === LocationCategories.events
    ) {
      return 1;
    }
    return 0;
  });
  return resultArray;
};

export default buildLocationArray;
