import Header from "../components/layouts/header";
import {display, styled} from '@mui/system';
import ChalkBoard1 from "../images/chalkboards/noita-digital-MdmcpX2ofRk-unsplash.jpg";
import OverHeadFlavors  from '../images/CHALKED IMAGES/Overhead chalked.jpg';
import OverHeadEdited from '../images/CHALKED IMAGES/edited/Overhead.png';
import Hero from "../components/features/Hero";
import FlavorsComponent from "../components/features/FlavorCompontent";
import { Container } from "react-bootstrap";
import FlavorsHero from "../images/flavorspage/4x3.png"
import Hero2 from "../components/features/Hero2";
import { baseTheme } from "../styles/themes/theme";
import StyledParagraph from "../components/styled/styledText";
const ParagraphText = "We are serious about delivering our desserts with the perfect blend of base flavors, complimentary ingredients and alcoholic enhancements. Additions such as nuts, fruits, pie crust or chocolates add to the experience while the specific alcohol blends are chosen to enhance the combinations for pure indulgence. Alcohol content approaches 5% to maintain the best combination of texture and flavor without overpowering the taste buds. The Swizzled promise is for you to have a delightful experience worthy of your purchase. We have also taken into consideration all our friends with different dietary choices or needs. In addition to our creamy ice cream selections, we offer flavors to include dairy free, gluten free and vegan options. Check the website frequently for new flavor introductions, seasonal and limited addition releases.";

// const StyledParagraphText = styled('p')(()=>({
//   //backgroundImage: `url(${ChalkBoard1})`,
//   fontFamily: baseTheme.typography.bodyText,
//   fontSize: '4rem',
//   color: "white",
//   padding: '50px 30px',

//   margin: '0'
// }));


export const FlavorsPage=()=>{

  const heroImageStyle = {
     
  }
  const overideForHeroColumnBreakpoints = {
    xs:{span:12, offset:0},
    xl:{span:6, offset:2}
  }
  const overideForHeroImageColumnBreakpoints = {
    xs:{span:12, offset:0},
    xl:{span:4, offset:0}
  }

  return (
  <>
    <Hero2
      heroText={"Our Flavors"}
    />

    
    <Container>
      <StyledParagraph text={ParagraphText}/>
    </Container>
    <FlavorsComponent/>

  </>
)}

export default FlavorsPage; 