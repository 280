import React, { useContext, useEffect } from "react";
import { Marker, useMarkerRef } from "@vis.gl/react-google-maps";
import FindUsContext from "../../../data/Contexts/FindUsContext";

const IconMarker = ({ position, icon, onClick, locationDetails }) => {
  const [markerRef, marker] = useMarkerRef();
  const context = useContext(FindUsContext);
  const { categories } = context;
  const [visible, setVisible] = React.useState();

  React.useEffect(() => {
    setVisible(categories[locationDetails.category]);
  }, [categories]);

  const localOnclick = (e) => {
    console.log("IconMarker onClick");
    console.log(marker);
    if (onClick) {
      marker.locationDetails = locationDetails;
      onClick(marker);
    }
  };

  return (
    <Marker
      ref={markerRef}
      position={position}
      icon={{
        url: icon,
        scaledSize: new window.google.maps.Size(30, 30),
      }}
      visible={visible}
      onClick={localOnclick}
    />
  );
};

export default IconMarker;
