import * as React from 'react';
import { Col, Row, Container, Button, Ratio } from 'react-bootstrap';
import { baseTheme } from '../../styles/themes/theme';
import Image from 'react-bootstrap/Image';
import styled from '@emotion/styled';
import { StyledCol } from '../styled/styledRowsCols';
import { NavLink } from 'react-router-dom';
import StyledParagraph, {StyledH1} from '../styled/styledText';
import { PrimaryButton } from '../styled/styledButtons';


const BannerContainer = styled(Container)(()=>({

})); 
const BannerContainerRow = styled(Row)(()=>({
    paddingTop: '50px',
    justifyContent: 'center',
    alignItems:'center'
}));




export const BannerwithImageFirst = ({bannerText, bannerTitle, bannerImg, bannerLink, bannerLinkText})=>{
    return (
        <BannerContainerRow>
        <Col xs={{span:8}} md={{span:5}} className={'gx-0'}>
        {/* <Ratio aspectRatio="4x3"> */}
            <Image src={bannerImg} className={"center-block"} rounded fluid/>
            {/* </Ratio> */}
        </Col>
        <Col xs={{span:12}} md={7}>
            <Row><StyledH1 text={bannerTitle}/></Row>
            <Row><StyledParagraph text={bannerText}/></Row>
            <Row>
                <StyledCol align="center">
                    <PrimaryButton as={NavLink} to={bannerLink}>
                                {bannerLinkText}
                    </PrimaryButton>
                </StyledCol> 
            </Row>
        </Col>

    </BannerContainerRow>
    )
}

export const BannerwithImage = ({bannerText, bannerTitle, bannerImg, bannerLink, bannerLinkText}) => {
    return (
        <BannerContainerRow>
            <Col xs={{span:8, order:1}} md={{span:5, order:2}} align="center" className={'gx-0'}>
            {/* <Ratio aspectRatio="16x9" > */}
                <Image src={bannerImg} rounded fluid/>
            {/* </Ratio> */}
            </Col>
            <Col xs={{span:12, order: 2}} md={{span: 7, order:1}}>  
            <Row><StyledH1 text={bannerTitle}/></Row>
            <Row><StyledParagraph text={bannerText}/></Row>
            <Row>
                <StyledCol className="align-self-center" align="center">
                    <PrimaryButton as={NavLink} to={bannerLink} >
                        {bannerLinkText}
                    </PrimaryButton>
                </StyledCol>
                
            </Row>
            </Col>
            
        </BannerContainerRow>
    )
};

export const Banner = ({bannerText, title })=>{
    return (
        <>
            <BannerContainer>
                
                    {bannerText}
                    
                
            </BannerContainer>
        </>
    )
}


export default Banner;