import * as React from 'react';
import { Stack } from '@mui/system';
import SelectUnstyled from '@mui/base/SelectUnstyled';
import OptionUnstyled from '@mui/base/OptionUnstyled';
import MenuUnstyled from '@mui/base/MenuUnstyled';
import MenuItemUnstyled from '@mui/base/MenuItemUnstyled';
import {baseTheme as theme} from '../../styles/themes/theme';
import Badge_Logo_Blue from '../../images/Badge_Logo_Blue.png';
import PB_Logo from '../../images/logos/PB_Logo.png';
import { baseTheme } from '../../styles/themes/theme';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {styled} from '@mui/system';
import { ButtonUnstyled } from '@mui/base';
import { grid } from '@mui/system';
import { Container, Navbar,Nav } from 'react-bootstrap';

const Item = styled(Nav.Link)(()=>({
    fontFamily: baseTheme.typography.h1Text, 
    fontSize: '1.2rem',
    color: theme.palette.secondary,
    textDecoration: 'none',
    padding: '15px',
    textTransform: 'uppercase',
    '&.active':{
        borderBottom: '3px solid '
    }
    
}));

const DropDownItem = styled(OptionUnstyled)(()=>({

}));

const DropDown = styled(SelectUnstyled)(()=>({

}));

const StyledStack = styled(Stack)(()=>({
    height: '50px'
}));

const LogoHeader = styled(`img`)(()=>({
    height:'85px',
    // width: '40px',
}));


const ContactMenu = ({})=> {
    return (
        <>
        <MenuUnstyled>
            <MenuItemUnstyled>Retailers</MenuItemUnstyled>
            <MenuItemUnstyled>Events</MenuItemUnstyled>
            <MenuItemUnstyled>Customers</MenuItemUnstyled>
        </MenuUnstyled>

        </>
    )
}
const ContactDropDown = ({})=>{
    return(
        <>
                    <DropDown defaultValue={"ContactUs"}>
                        <DropDownItem value={"ContactUs"}>Contact Us</DropDownItem>
                        <DropDownItem value={"#Retailers"}>Retailers</DropDownItem>
                        <DropDownItem value={"#Events"}>Events</DropDownItem>
                        <DropDownItem value={"#Customers"}>Customers</DropDownItem>
                    </DropDown>
        </>
    )
}


const StyledSpan= styled('span')(() => ({
    fontFamily: baseTheme.typography.Berton, 
    color: baseTheme.palette.primaryWhite,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '10px',
    paddingLeft: '10px'
}));

const Header = ({}) =>{
const [active, setActive]= React.useState("home");
const location = useLocation();

function handleButtonClick(){
    
}

return (
    <Navbar fixed='top' style={{background:baseTheme.general.backgroundColor}} collapseOnSelect expand="md" className='navbar-dark'>
    <Container>
        <Navbar.Brand as={NavLink}  to="/" style={{alignItems: 'center'}}>
            <LogoHeader  src={PB_Logo} href="/" alt="Swizzled Logo"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse className="justify-content-end" variant='dark'>
                <Nav
                    defaultActiveKey={active}
                >
                <Item as={NavLink} to="/About" variant="contained">About</Item>
                <Item as={NavLink}  to="/OurFlavors" variant="contained">Our Flavors</Item>
                <Item as={NavLink} to="/FindUs" variant="contained">Where To Find Us</Item>
                <Item as={NavLink} to="/ContactUs">Contact Us </Item>                   
                </Nav>
            </Navbar.Collapse>
            {/* </Stack> */}
        </Container>
    </Navbar>

)  
}

export default Header;