import styled from '@emotion/styled';
import { baseTheme } from '../../styles/themes/theme';


const StyledText = styled('p')(()=>({
    textAlign: 'justify',
    color: baseTheme.palette.primaryWhite,
    fontFamily: baseTheme.typography.bodyText,
    '@media screen and (max-width:769px)':
    {
      fontSize: '1.2rem',
      textAlign:'center'
    },
    '@media screen and (min-width: 770px) and (max-width:1200px)':
    {
      fontSize: '1.5rem'
    },
    fontSize: '1.8rem'
}));
 

const StyledHeaders = styled('h1')(()=>({
    fontFamily: baseTheme.typography.h1Text,
    fontSize: '3rem', 
    textAlign: 'center',
    color: baseTheme.palette.primaryWhite,
    textTransform: 'uppercase',
    paddingTop: '10px',
    '@media screen and (max-width:769px)':
    {
      fontSize: '2.5rem',
      textAlign:'center'
    },
    '@media screen and (min-width: 770px) and (max-width:1200px)':
    {
      fontSize: '3rem'
    },
}));

export const StyledH1 = ({text})=>{
    return <StyledHeaders>{text}</StyledHeaders>
}


export const StyledParagraph = ({text})=>{
    return <StyledText>{text}</StyledText>
}

export default StyledParagraph;