import styled from '@emotion/styled';

import { Col, Row } from 'react-bootstrap';
import { baseTheme } from '../../styles/themes/theme';


const StyledRowA = styled(Row)(({align})=>({
    textAlign: {align}
}))

const StyledColA = styled(Col)(({align})=>({
    textAlign: {align},
    margin: 'auto',
    

}))

export const StyledRow = ({children, align})=>{
    return <StyledRowA align={align}>
        {children}
    </StyledRowA>
}


export const StyledCol = ({children, align, xs, sm, md})=>{
    return <StyledColA align={align} xs={xs} sm={sm} md={md}>
        {children}
    </StyledColA>
}