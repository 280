import * as React from "react";
// import {Box, flexbox, styled} from '@mui/system';
// import { Container } from 'react-bootstrap';
// import Grid from '@mui/system/Unstable_Grid/Grid';
import {
  Container,
  Row,
  Col,
  Card,
  Ratio,
  Badge,
  Stack,
  CardGroup,
} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import styled from "@emotion/styled";
// import ChocolateTrifecta from "../../images/flavors/Ingredients/CT SHARPENED.jpg";
import ChocolateTrifectaContainer from "../../images/flavors/Containers/ChocolateTrifecta.png";
import ChocolateTrifectaIngredients from "../../images/flavors/Ingredients/png/CT.png";
import ChunkyPBC from "../../images/flavors/Ingredients/png/PB&C.png";
import ChuckyPBCContainer from "../../images/flavors/Containers/Chunky_PBC.png";
import CinnamonApple from "../../images/flavors/Ingredients/png/CA.png";
import CinanamonAppleContainer from "../../images/flavors/Containers/Cinnamon_Apple.png";
import KentuckyMapplePecan from "../../images/flavors/Ingredients/png/KMP.png";
import KentuckyMapplePecanContainer from "../../images/flavors/Containers/Kentucky_Maple.png";
import MGC from "../../images/flavors/Ingredients/png/MGC.png";
import MGCContainer from "../../images/flavors/Containers/GodfathersCannoli.png";
import PineappleMango from "../../images/flavors/Ingredients/png/PMC.png";
import PineappleMangoContainer from "../../images/flavors/Containers/Pineapple_Mango_Chill.png";
import RaspberryLemonade from "../../images/flavors/Ingredients/png/RLA.png";
import RaspberryLemonadeContainer from "../../images/flavors/Containers/RaspberryLemonAgave.png";
// import SoHoBerry from "../../images/flavors/Ingredients/SBC SHARPENED.jpg";
// import SoHoBerryContainer from "../../images/flavors/Containers/Soho_Berry.png";
import Smores from "../../images/flavors/Ingredients/png/SmoreCookies.png";
import SmoreContainer from "../../images/flavors/Containers/Smore.png";

import CaramelPretzel from "../../images/flavors/Ingredients/png/CaramelPretzel.png";
import CaramelPretzelContainer from "../../images/flavors/Containers/Pretzel.png";
// import TipsyChimp from "../../images/flavors/Ingredients/TC SHARPENED.jpg";
// import TipsyChimpContainer from "../../images/flavors/Containers/Tipsy_Chimp.png";
import WakeUpCall from "../../images/flavors/Ingredients/png/WUC.png";
import WakeUpCallContainer from "../../images/flavors/Containers/WakeUp_Call.png";

import SpiceIslandVanilla from "../../images/flavors/Ingredients/Spice Island Vanilla.jpg";
import SpiceIslandVanillaContainer from "../../images/flavors/Containers/Spice Island Vanilla.png";
import FieryPassionSorbet from "../../images/flavors/Ingredients/Fiery Passion.jpg";
import FieryPassionSorbetContainer from "../../images/flavors/Containers/Fiery Passion.png";
import CitrusSunsetSorbet from "../../images/flavors/Ingredients/Citrus Sunset.jpg";
import CitrusSunsetSorbetContainer from "../../images/flavors/Containers/Citrus Sunset.png";

import { ThemeContext } from "@emotion/react";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { baseTheme } from "../../styles/themes/theme";
import FlavorCard from "./FlavorComponents";

const FlavorsList = [
  {
    Title: "Chocolate Trifecta",
    pintImg: ChocolateTrifectaContainer,
    ingredientsImg: ChocolateTrifectaIngredients,
    flavorDescr:
      "Rich dark chocolate ice cream, loaded with chocolate chunks and blended exclusively with Ballotin chocolate whiskey.",
    glutenFree: true,
    vegan: false,
    dairyFree: false,
  },
  {
    Title: "My Godfather's Cannoli",
    pintImg: MGCContainer,
    ingredientsImg: MGC,
    flavorDescr:
      "Crushed fresh cannoli shells and mini chocolate chips submerged in a silky amaretto and bourbon cannoli ice cream blend.",
    glutenFree: false,
    vegan: false,
    dairyFree: false,
  },
  // {
  //   Title: "Cinnamon Apple A La Mode",
  //   pintImg: CinanamonAppleContainer,
  //   ingredientsImg: CinnamonApple,
  //   flavorDescr:
  //     "Fresh baked apples and broken crust smothered in a base of vanilla ice cream flavord with apple cinnamon whiskey.",
  //   glutenFree: false,
  //   vegan: false,
  //   dairyFree: false,
  // },
  {
    Title: "Kentucky Maple Pecan",
    pintImg: KentuckyMapplePecanContainer,
    ingredientsImg: KentuckyMapplePecan,
    flavorDescr:
      "Decadent maple flavored ice cream loaded with chopped pecans and drowned in small batch bourbon.",
    glutenFree: true,
    vegan: false,
    dairyFree: false,
  },
  // {
  //   Title: "Soho Berry Cheesecake",
  //   pintImg: SoHoBerryContainer,
  //   ingredientsImg: SoHoBerry,
  //   flavorDescr:
  //     "Cheesecake flavored ice cream made with raspberry and strawberry puree enhanced with Rose vodka. Graham cracker crust included.",
  //   glutenFree: false,
  //   vegan: false,
  //   dairyFree: false,
  // },

  // {
  //   Title: "Chunky PB & C",
  //   pintImg: ChuckyPBCContainer,
  //   ingredientsImg: ChunkyPBC,
  //   flavorDescr:
  //     "Peanut butter ice cream, loaded with chopped peanuts and chunks of dark chocolate blended with peanut butter whiskey.",
  //   glutenFree: false,
  //   vegan: false,
  //   dairyFree: false,
  // },
  // {
  //   Title: "Tipsy Chimp",
  //   pintImg: TipsyChimpContainer,
  //   ingredientsImg: TipsyChimp,
  //   flavorDescr:
  //     "Rich banana flavored dairy free ice cream ripened with brown Caribbean rum and chopped walnuts for a delightful island treat.",
  //   glutenFree: true,
  //   vegan: false,
  //   dairyFree: true,
  // },
  {
    Title: "Wake Up Call",
    pintImg: WakeUpCallContainer,
    ingredientsImg: WakeUpCall,
    flavorDescr:
      'Classic dairy free coffee ice cream "brewed" with fresh ground Colombian coffee energized by double espresso vodka.',
    glutenFree: true,
    vegan: true,
    dairyFree: true,
  },
  {
    Title: "Pineapple Mango Chill Sorbet",
    pintImg: PineappleMangoContainer,
    ingredientsImg: PineappleMango,
    flavorDescr:
      "Cool off with frozen pineapple chunks embedded in mango flavored sorbet and chilled with a blend of flavored vodkas.",
    glutenFree: true,
    vegan: true,
    dairyFree: true,
  },
  {
    Title: "Lemon Raspberry Agave",
    pintImg: RaspberryLemonadeContainer,
    ingredientsImg: RaspberryLemonade,
    flavorDescr:
      "An intoxicating combination of sweet and sour fruit sorbets “spiked” with a premium tequila blanco.",
    glutenFree: true,
    vegan: true,
    dairyFree: true,
  },
  {
    Title: "Caramel Pretzel Crunch",
    pintImg: CaramelPretzelContainer,
    ingredientsImg: CaramelPretzel,
    flavorDescr:
      "Salted pretzel bits surrounded by creamy caramel flavored ice cream. Blended exclusively with Ballotin Caramel Turtle Whiskey.",
    glutenFree: false,
    vegan: false,
    dairyFree: false,
  },
  {
    Title: "S'more Cookies Please",
    pintImg: SmoreContainer,
    ingredientsImg: Smores,
    flavorDescr:
      "Imagine mixing S’mores treats with chocolate chip cookies? Imagine no more! Graham crackers, marshmallows, chocolate and the infusion of chocolate chip cookie liqueur.",
    glutenFree: false,
    vegan: false,
    dairyFree: false,
  },
  ////
  {
    Title: "Spice Island Vanilla",
    pintImg: SpiceIslandVanillaContainer,
    ingredientsImg: SpiceIslandVanilla,
    flavorDescr:
      "Silky vanilla ice cream blended with spiced rum from the Caribbean. Ground cinnamon and nutmeg combine for a bold flavor experience.",
    glutenFree: true,
    vegan: false,
    dairyFree: false,
  },
  {
    Title: "Fiery Passion Sorbet",
    pintImg: FieryPassionSorbetContainer,
    ingredientsImg: FieryPassionSorbet,
    flavorDescr:
      " Pinaq tropical liqueur provides the perfect accent to a blend of sweet strawberries and tart passion fruit with the fiery bite of ground jalapeno.",
    glutenFree: true,
    vegan: true,
    dairyFree: true,
  },
  {
    Title: "Citrus Sunset Sorbet",
    pintImg: CitrusSunsetSorbetContainer,
    ingredientsImg: CitrusSunsetSorbet,
    flavorDescr:
      "The combination of lemon and mandarin orange create the citrus base for a refreshing infusion of Pinaq Rose Liqueur and vodka.",
    glutenFree: true,
    vegan: true,
    dairyFree: true,
  },
];

const ProductRow = styled(Row)(() => ({
  // height: '500px'
}));

const ProductImageDiv = styled(Image)(() => ({
  alignItems: "center",
  padding: "5px",
  margin: "5px",
  // height:'475px',
  objectFit: "contain",
}));

const IngredientsImageDiv = styled(Image)(() => ({
  alignItems: "center",
  padding: "5px",
  margin: "5px",
  height: "475px",
  objectFit: "cover",
}));

const ProductCard = styled(Card)(() => ({
  height: "475px",
  background: baseTheme.general.backgroundColor,
}));

const ProductDesc = styled(Card.Text)(() => ({
  textAlign: "center",
  color: "white",
}));

const DietaryBadge = styled(Badge)(() => ({
  fontFamily: baseTheme.typography.bodyText,
  fontSize: ".8rem",
  color: baseTheme.palette.primaryWhite,
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
  backgroundColor: baseTheme.general.backgroundColor,
  border: `2.5px solid ${baseTheme.palette.primaryWhite}`,
  borderRadius: "50%",
  margin: "auto",
  justifyContent: "center",
  height: "60px",
  width: "60px",
  whiteSpace: "pre-wrap",
}));

const DietaryBadgeLower = styled(DietaryBadge)(() => ({
  border: `1px solid ${baseTheme.palette.primaryWhite}`,
}));
const EmptyBadge = styled(DietaryBadge)(() => ({
  color: baseTheme.general.backgroundColor,
  backgroundColor: baseTheme.general.backgroundColor,
  border: `2.5px solid ${baseTheme.general.backgroundColor}`,
}));

export const Flavor = ({ flavorObject }) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <Col className="">
      <ProductCard
        className="border-0"
        border="light"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {!hovered ? (
          <>
            <Stack
              direction="horizontal"
              gap={1}
              style={{ margin: "15px auto 0 auto" }}
            >
              {flavorObject.glutenFree ? (
                <DietaryBadge bg={""}>Gluten Free</DietaryBadge>
              ) : (
                <EmptyBadge bg={""}>'</EmptyBadge>
              )}
              {flavorObject.vegan ? (
                <DietaryBadge bg={""}>Vegan</DietaryBadge>
              ) : (
                <></>
              )}
              {flavorObject.dairyFree ? (
                <DietaryBadge bg={""}>Dairy Free</DietaryBadge>
              ) : (
                <></>
              )}
            </Stack>
            <ProductImageDiv
              src={flavorObject.pintImg}
              className=" object-fit-cover"
            />
          </>
        ) : (
          <>
            <IngredientsImageDiv src={flavorObject.ingredientsImg} />
            <Card.ImgOverlay>
              <Card.Header className="text-light">
                {flavorObject.Title}
              </Card.Header>
              <ProductDesc className="text-light">
                {flavorObject.flavorDescr}
              </ProductDesc>
              <Stack
                direction="horizontal"
                gap={1}
                style={{ position: "absolute", bottom: 20 }}
              >
                {flavorObject.glutenFree ? (
                  <DietaryBadgeLower bg={""}>Gluten Free</DietaryBadgeLower>
                ) : (
                  <></>
                )}
                {flavorObject.vegan ? (
                  <DietaryBadgeLower bg={""}>Vegan</DietaryBadgeLower>
                ) : (
                  <></>
                )}
                {flavorObject.dairyFree ? (
                  <DietaryBadgeLower bg={""}>Dairy Free</DietaryBadgeLower>
                ) : (
                  <></>
                )}
              </Stack>
            </Card.ImgOverlay>
          </>
        )}
      </ProductCard>
    </Col>
  );
};

export const FlavorsComponent = () => {
  let Flavors = FlavorsList;
  return (
    <>
      <Container>
        {/* <Grid container spacing={0}> */}
        <ProductRow xs={1} sm={1} md={2} lg={3} className="g-5">
          {Flavors.map((FlavorObj, index) => {
            return (
              <Col key={index} style={{ display: "flex" }}>
                <FlavorCard key={index} flavorObject={FlavorObj} />
              </Col>
            );
          })}
          {/* </Grid> */}
        </ProductRow>
      </Container>
    </>
  );
};

export default FlavorsComponent;
