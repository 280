import { createTheme } from "@mui/system";
import { primaryColors, flavorColors } from "./colors.js";
import fonts from "./fonts.js";
export const baseTheme = createTheme({
  general: {
    backgroundColor: "#161616",
  },
  palette: {
    primary: "#111",
    secondary: "#CDB297",
    tan: "#dda665",
    primaryWhite: "#f5f5f5",
    black: "#000",
    blue: "#2b6be4",
  },
  typography: {
    bodyText: fonts.LeHavre.rounded,
    h1Text: fonts.Guilia.plain,
    bodyTextSize: "2rem",
    infoWindow: {
      detail: {
        fontFamily: fonts.LeHavre.rounded,
        textAlign: "left",
        fontSize: "1rem",
        margin: "3px 0",
      },
    },
  },

  buttons: {
    backgroundColor: "#CDB297",
  },
});
