import * as React from "react";
import { InfoWindow } from "@vis.gl/react-google-maps";
import styled from "@emotion/styled";
import { baseTheme } from "../../../../styles/themes/theme";
import { Container, Row, Col, Image } from "react-bootstrap";
import { LocationLabels } from "../../../../data/Constants/LocationLabels";
import { EventDateTimeDisplay } from "./EventDateTimeDisplay";
import { SwizzledSiteDisplay } from "./SiteDisplay";
import AddressDisplay from "./AddressDisplay";
import PhoneDisplay from "./PhoneDisplay";

const LocationTitle = styled("h4")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "1.1rem",
  fontWeight: "bold",
}));

const LocationLabel = styled("p")(() => ({
  textAlign: baseTheme.typography.infoWindow.detail.textAlign,
  fontFamily: baseTheme.typography.bodyText,
  fontSize: ".8rem",
  padding: "4px",
  margin: baseTheme.typography.infoWindow.detail.margin,
}));

export const SwizzledInfoWindow = ({
  position,
  anchor,
  onCloseClick,
  locationDetails,
}) => {
  return (
    <InfoWindow
      //anchor={anchor}
      // pixelOffset={(0, 0)}
      position={position}
      onCloseClick={onCloseClick}
      onClose={onCloseClick}
    >
      {locationDetails ? (
        <SwizzledInfoWindowContent location={locationDetails} />
      ) : (
        <div>
          <h1>Info Window</h1>
          <p>This is an info window</p>
        </div>
      )}
    </InfoWindow>
  );
};

export const SwizzledInfoWindowContent = ({ location }) => {
  const address = location && location.address ? location.address : null;

  return (
    <Container key={location.key} style={{ minWidth: "200px" }}>
      <Row>
        <Col
          xs={7}
          style={{ alignContent: "center", borderRight: "1px solid black" }}
        >
          <Row style={{ justifyContent: "center", padding: "4px" }}>
            <Col xs={1} style={{ alignContent: "center" }}>
              <Image
                height={25}
                width={25}
                src={location.icon}
                style={{ margin: "auto" }}
                rounded
              />
            </Col>
            <Col
              xs="auto"
              style={{ alignContent: "center", textAlign: "center" }}
            >
              <LocationLabel>{LocationLabels[location.category]}</LocationLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <LocationTitle>{location.name}</LocationTitle>
            </Col>
          </Row>
        </Col>
        <Col>
          {location.eventDetails.startTime && (
            <EventDateTimeDisplay
              startTime={location.eventDetails.startTime}
              endTime={location.eventDetails.endTime}
            />
          )}
          <AddressDisplay address={address} />

          {location.phoneNumber && (
            <PhoneDisplay phoneNumber={location.phoneNumber} />
          )}
          {location.site && (
            <SwizzledSiteDisplay
              href={location.site}
              label={location?.name}
              hideSiteName
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SwizzledInfoWindow;
