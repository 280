import * as React from "react";
import styled from "@emotion/styled";
import { baseTheme } from "../../styles/themes/theme";
import { Button } from "react-bootstrap";
const LocationTitle = styled("h4")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "1.2rem",
  fontWeight: "bold",
}));
const LocationAddress = styled("p")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.1rem",
  paddingTop: "0",
  marginBottom: "0",
}));
const LocationContact = styled("p")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "0.9rem",
  paddingTop: "0",
  marginBottom: "0",
}));
const LocationLink = styled(Button)(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1rem",
}));
const InfoWindowContent = ({ marker }) => {
  const [details, setDetails] = React.useState(
    marker ? marker.props.details : null
  );
  const [name, setName] = React.useState(
    marker ? marker.props.details.name : null
  );
  const [type, setType] = React.useState(marker ? marker.props.type : null);
  // const [title, setTitle] = React.useState(marker ? marker.props.name : null);
  const [dateStr, setDateStr] = React.useState("");
  const [retailerName, setRetailerName] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [zipcode, setZipCode] = React.useState("");

  React.useEffect(() => {
    if (marker) {
      setRetailerName(
        marker.props.details ? marker.props.details.retailerName : null
      );
      setDateStr(
        marker.props.details.DateStr ? marker.props.details.DateStr : null
      );
      setLocation(
        marker.props.details.Address
          ? marker.props.details.Address.location
          : null
      );
      setName(
        marker.props.details.name
          ? marker.props.details.name
          : marker.props.details.Name
      );
      setLocation(
        marker.props.details.Address
          ? marker.props.details.Address.location
          : ""
      );
      setStreet(
        marker.props.details.Address
          ? marker.props.details.Address.street
          : marker.props.details.address.street
      );
      setCity(
        marker.props.details.Address
          ? marker.props.details.Address.city
          : marker.props.details.address.city
      );
      setState(
        marker.props.details.Address
          ? marker.props.details.Address.state
          : marker.props.details.address.state
      );
      setZipCode(
        marker.props.details.Address
          ? marker.props.details.zipcode
          : marker.props.details.address.zipcode
      );
    }
  }, [marker]);

  return (
    <div>
      {retailerName && <LocationTitle>{retailerName}</LocationTitle>}
      <LocationTitle>{name}</LocationTitle>
      {dateStr && <LocationTitle>{dateStr}</LocationTitle>}
      {location && <LocationAddress>{location}</LocationAddress>}
      <LocationAddress>
        {street}, {city}, {state}, {zipcode}
      </LocationAddress>
    </div>
  );
};

export default InfoWindowContent;
