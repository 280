import styled from "@emotion/styled";

const StyledSvg = styled(`svg`)(({ bottom }) => ({
  display: "block",
  position: "absolute",
  // background: '#000',
  bottom: bottom,
}));

const StyledSvgInverted = styled(`svg`)(({ top }) => ({
  // WebkitFilter: 'invert(100%)',

  // filter:'invert(100%)',
  display: "block",
  position: "absolute",
  // background: '#000',
  top: top,
}));

export const CurvedBorder = ({
  backgroundColor,
  innerBandColor,
  outerBandColor,
  bottom,
  top,
}) => {
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      bottom={bottom}
      viewBox="0 0 1440 318"
    >
      <path
        fill={outerBandColor}
        fillOpacity="1"
        d="M0,180
            C96,128,192,96,288,112
            C384,128,480,192,576,213.3
            C672,235,768,213,864,192
            C960,171,1056,149,1152,154.7
            C1248,160,1344,192,1440,224
            L1440,320
            L0,320Z"
      ></path>
      <path
        fill={innerBandColor}
        fillOpacity="1"
        d="M0,200
            C96,148,192,116,288,132
            C374,148,480,212,576,233
            C662,255,778,233,864,212
            C950,191,1056,169,1152,174
            C1248,180,1344,212,1440,244
            L1440,320
            L0,320Z"
      ></path>
      <path
        fill={backgroundColor}
        fillOpacity="1"
        d="M0,220
            C96,168,192,136,288,152
            C374,168,480,232,576,253
            C662,275,778,253,864,232
            C950,211,1056,189,1152,194
            C1248,200,1344,232,1440,264
            L1440,320
            L0,320Z"
      ></path>
    </StyledSvg>
  );
};

export const CurvedBorderTop = ({
  backgroundColor,
  innerBandColor,
  outerBandColor,
  top,
}) => {
  return (
    <StyledSvgInverted
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill={outerBandColor}
        fillOpacity="1"
        d="M0,160
            C96,128,192,96,288,112
            C384,128,480,192,576,213.3
            C672,235,768,213,864,192
            C960,171,1056,149,1152,154.7
            C1248,160,1344,192,1440,208
            L1440,0
            L0,0Z"
      ></path>
      <path
        fill={innerBandColor}
        fillOpacity="1"
        d="M0,140
            C96,108,192,76,288,92
            C384,108,480,172,576,193.3
            C672,215,768,193,864,172
            C960,151,1056,129,1152,134.7
            C1248,140,1344,172,1440,188
            L1440,0
            L0,0Z"
      ></path>
      <path
        fill={backgroundColor}
        fillOpacity="1"
        d="M0,120
            C96,88,192,56,288,72
            C384,88,480,152,576,173.3
            C672,195,768,173,864,152
            C960,131,1056,109,1152,114.7
            C1248,120,1344,152,1440,168
            L1440,0
            L0,0Z"
      ></path>
    </StyledSvgInverted>
  );
};

export default CurvedBorder;
