import * as React from "react";
import { ListGroup } from "react-bootstrap";
import RetailersList from "../../../data/Retailers";
import RestaurantList from "../../../data/Resturants";
import EventsList from "../../../data/Events";
import { LocationListGroupItem } from "./Items/ListGroupItems";
import buildLocationArray from "../../util/buildLocationArray";

const data = buildLocationArray(RestaurantList, EventsList, RetailersList);

export const LocationListGroupDisplay = () => {
  return (
    <ListGroup>
      {data.map((location, index) => {
        return <LocationListGroupItem key={index} location={location} />;
      })}
    </ListGroup>
  );
};

export default LocationListGroupDisplay;
