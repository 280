import * as React from "react";
import EventsList from "../../data/Events";
import EventCalendar from "../../components/features/Calendar";
import {
  Accordion,
  Row,
  Col,
  Button,
  Card,
  CardGroup,
  Image,
  ListGroup,
  ListGroupItem,
  Ratio,
} from "react-bootstrap";
import styled from "@emotion/styled";
import { baseTheme } from "../../styles/themes/theme";

const EventDetailLabel = styled(`span`)(({}) => ({
  textAlign: "justify",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const EventDetailValue = styled(`span`)(({}) => ({
  textAlign: "justify",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.2rem",
}));

const EventWebLink = styled(`a`)(({}) => ({
  textAlign: "justify",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.2rem",
}));

const EventAccordion = styled(Accordion)(({}) => ({
  //backgroundColor: baseTheme.general.backgroundColor,
}));

const LatestEventsSection = ({ events }) => {
  const [selectedEvent, setSelectedEvent] = React.useState();

  let objArr = events;

  function eventClicked(e) {}

  function eventSelected(e) {
    setSelectedEvent(e.date);
  }

  return (
    <>
      <Row md={1}>
        <Col>
          <EventAccordion flush>
            {EventsList.length === 0 && (
              <h2
                style={{
                  fontFamily: baseTheme.typography.h1Text,
                }}
              >
                More Events Coming Soon!!
              </h2>
            )}
            {EventsList.length !== 0 &&
              EventsList.map((event, index) => {
                return (
                  <>
                    <Accordion.Item
                      key={index}
                      eventKey={index}
                      onClick={() => eventSelected(event)}
                    >
                      <Accordion.Header
                        style={{
                          fontFamily: baseTheme.typography.h1Text,
                          fontSize: "2rem",
                        }}
                      >
                        {event.Title}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <EventDetailLabel className="eventLabel">
                            Date:{" "}
                          </EventDetailLabel>
                          <EventDetailValue>{event.DateStr}</EventDetailValue>
                        </div>
                        {event.Time && (
                          <div>
                            <EventDetailLabel className="eventLabel">
                              Time:{" "}
                            </EventDetailLabel>
                            <EventDetailValue>{event.Time}</EventDetailValue>
                          </div>
                        )}
                        <div>
                          <EventDetailLabel className="eventLabel">
                            Location:{" "}
                          </EventDetailLabel>
                          <EventDetailValue>
                            {event.Address.location ? (
                              <>
                                {event.Address.location} <br></br>{" "}
                              </>
                            ) : (
                              <></>
                            )}
                            {event.Address.street}, {event.Address.city},{" "}
                            {event.Address.state}, {event.Address.zipcode}
                          </EventDetailValue>
                        </div>
                        {event.Site ? (
                          <div>
                            <EventWebLink target="_blank" href={event.Site}>
                              Visit Site
                            </EventWebLink>
                          </div>
                        ) : (
                          <></>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
          </EventAccordion>
        </Col>
        <Col>
          {EventsList.length !== 0 && (
            <EventCalendar
              eventsList={EventsList}
              eventClickedCallback={eventClicked}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default LatestEventsSection;
