import styled from "@emotion/styled";
import { Col, Card, Carousel } from "react-bootstrap";
import { baseTheme } from "../../styles/themes/theme";

const LocationDetailLabel = styled(`span`)(({}) => ({
  textAlign: "justify",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const LocationDetailValue = styled(`span`)(({}) => ({
  textAlign: "justify",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.2rem",
}));

const LocationDetailAddress = styled(`p`)(({}) => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.2rem",
  paddingTop: "0",
  marginBottom: "0",
}));

const LocationWebLink = styled(`a`)(({}) => ({
  textAlign: "justify",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.2rem",
}));

export const LocationCard = ({ location }) => {
  let name = location.name;
  let address = location.address;

  return (
    <Col>
      <Card style={{ border: 0 }}>
        <Card.Body>
          <Card.Title
            style={{
              fontFamily: baseTheme.typography.h1Text,
              fontSize: "1.8rem",
            }}
          >
            {name}
          </Card.Title>
          {location.subText && (
            <div
              style={{
                fontFamily: baseTheme.typography.h1Text,
                fontSize: "1rem",
              }}
            >
              ( {location.subText} )
            </div>
          )}
          <Card.Text>
            <LocationDetailValue>
              {address.street}, {address.city}, {address.state},{" "}
              {address.zipcode}
            </LocationDetailValue>
          </Card.Text>
          {location.site ? (
            <LocationWebLink target="_blank" href={location.site}>
              Visit Site
            </LocationWebLink>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export const MultipleLocationCard = ({ retailer }) => {
  console.log(retailer);
  let locations = retailer ? retailer.locations : null;
  let site = retailer ? retailer.site : null;
  return (
    <Col>
      <Card style={{ border: 0 }}>
        <Card.Body>
          <Card.Title
            style={{
              fontFamily: baseTheme.typography.h1Text,
              fontSize: "1.8rem",
            }}
          >
            {retailer.name}
          </Card.Title>
          <Carousel variant="dark">
            {locations ? (
              locations.map((location, index) => {
                let address = location.address;
                return (
                  <Carousel.Item>
                    <Card.Title
                      style={{
                        fontFamily: baseTheme.typography.h1Text,
                        fontSize: "1.5rem",
                      }}
                    >
                      {location.name}
                    </Card.Title>
                    <Card.Text>
                      <LocationDetailAddress>
                        {address.street},{" "}
                      </LocationDetailAddress>
                      <LocationDetailAddress>
                        {address.city}, {address.state}, {address.zipcode}
                      </LocationDetailAddress>
                    </Card.Text>
                  </Carousel.Item>
                );
              })
            ) : (
              <></>
            )}
          </Carousel>
          {site ? (
            <LocationWebLink target="_blank" href={site}>
              Visit Site
            </LocationWebLink>
          ) : (
            <></>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default LocationCard;
