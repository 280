import React from "react";
import styled from "@emotion/styled";
import { baseTheme } from "../../../../styles/themes/theme";
import { Row, Col } from "react-bootstrap";
import { FiCalendar as DateIcon } from "react-icons/fi";
import { FiClock as TimeIcon } from "react-icons/fi";

const LocationDate = styled("div")(({ textAlign }) => ({
  textAlign: textAlign ?? baseTheme.typography.infoWindow.detail.textAlign,
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "0.9rem",
  paddingTop: "0",
  margin: baseTheme.typography.infoWindow.detail.margin,
}));

export const EventDateTimeDisplay = ({ startTime, endTime, textAlign }) => {
  const date = startTime
    ? new Date(startTime).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : null;

  let time = startTime
    ? new Date(startTime).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })
    : null;
  if (endTime) {
    time +=
      " - " +
      new Date(endTime).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
  }

  let _endDate = new Date(endTime);
  let _startDate = new Date(startTime);

  if (_endDate.getDate() !== _startDate.getDate()) {
    time =
      new Date(startTime).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }) +
      " - " +
      new Date(endTime).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
      }) +
      " " +
      new Date(endTime).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
  }

  let rowStyle = textAlign === "center" ? { justifyContent: "center" } : {};
  return (
    <>
      {date && (
        <Row style={rowStyle}>
          {/* <Col xs={1} style={{ alignContent: "center" }}>
            <DateIcon />
          </Col> */}
          <Col xs={"auto"}>
            <LocationDate textAlign={textAlign}>{date}</LocationDate>
            {startTime !== endTime && (
              <LocationDate textAlign={textAlign}>{time}</LocationDate>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default EventDateTimeDisplay;
