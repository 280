import styled from '@emotion/styled';
import { fontFamily } from '@mui/system';
import { Button, Col, Row } from 'react-bootstrap';
import { baseTheme } from '../../styles/themes/theme';



export const StyledButton = styled(Button)(()=>({
    backgroundColor: baseTheme.palette.primary,
    borderColor: baseTheme.buttons.backgroundColor,
    color: baseTheme.palette.secondary
}))


export const PrimaryButton = ({children, as, to})=> {
    return <StyledButton as={as} to={to}>
        {children}
    </StyledButton>
}