import React from "react";
import styled from "@emotion/styled";
import { baseTheme } from "../../../../styles/themes/theme";
import { Row, Col } from "react-bootstrap";
import { FiPhone as PhoneIcon } from "react-icons/fi";

const LocationPhoneNumber = styled("div")(({ textAlign }) => ({
  textAlign: textAlign ?? baseTheme.typography.infoWindow.detail.textAlign,
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "0.8rem",
  margin: 0,
}));

export const PhoneDisplay = ({ phoneNumber, textAlign }) => {
  let rowStyle = textAlign === "center" ? { justifyContent: "center" } : {};
  return (
    <Row style={rowStyle}>
      {/* <Col
        xs={1}
        style={{ alignContent: textAlign === "center" ? "end" : "center" }}
      >
        <PhoneIcon />
      </Col> */}
      <Col xs={"auto"}>
        <LocationPhoneNumber textAlign={textAlign}>
          {phoneNumber}
        </LocationPhoneNumber>
      </Col>
    </Row>
  );
};

export default PhoneDisplay;
