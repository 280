const EventsList = [
  {
    DateStr: "Sunday, April 28th",
    Date: "2024-04-28",
    Time: "11am - 4 PM",
    StartTime: "2024-04-28 11:00 AM",
    EndTime: "2024-04-28 4:00 PM",
    Title: "Tobay Beach Spring Classic Car Show",
    Address: {
      location: "Tobay Beach",
      street: "Ocean Pkwy",
      city: "Massapequa",
      location: "Tobay Beach",
      street: "Ocean Pkwy",
      city: "Massapequa",
      state: "NY",
      zipcode: "11549",
    },
    Site: "https://www.carshowli.com/",
    latLng: {
      lat: 40.612482571979484,
      lng: -73.43184079936503,
    },
  },
  {
    DateStr: "Saturday, May 11th",
    Date: "2024-05-11",
    Time: "12 - 8 PM",
    StartTime: "2024-05-11 12:00 PM",
    EndTime: "2024-05-11 8:00 PM",
    Title: "Great South Bay Feast Fest",
    Address: {
      location: "Great South Bay Brewery",
      street: "25 Drexel Drive",
      city: "Bay Shore",
      state: "NY",
      zipcode: "11706",
    },
    Site: "https://www.eventbrite.com/e/long-island-feast-fest-2024-tickets-827201390627",
    latLng: {
      lat: 40.76129371375533,
      lng: -73.26353733096698,
    },
  },
  {
    DateStr: "Saturday, May 18th",
    Date: "2024-05-18",
    Time: "1 - 4 PM",
    StartTime: "2024-05-18 1:00 PM",
    EndTime: "2024-05-18 4:00 PM",
    Title: "LI Whiskey Fest",
    Address: {
      location: "Tanger Outlets",
      street: "152 The Arches Circle",
      city: "Deer Park",
      state: "NY",
      zipcode: "11729",
    },
    Site: "https://www.eventbrite.com/e/long-island-whiskey-and-spirits-fest-tickets-858894846607",
    latLng: {
      lat: 40.76323081969923,
      lng: -73.3063026516147,
    },
  },
  {
    DateStr: "Sunday, May 19th",
    Date: "2024-05-19",
    Time: "1 - 5 PM",
    StartTime: "2024-05-19 1:00 PM",
    EndTime: "2024-05-19 5:00 PM",
    Title: "Brightwaters Spring Thing Block Party",
    Address: {
      location: "The Four Corners",
      street: "Downtown",
      city: "Brightwaters",
      state: "NY",
      zipcode: "11718",
    },
    Site: "https://www.eventbrite.com/e/long-island-whiskey-and-spirits-fest-tickets-858894846607",
    latLng: {
      lat: 40.73061060567905,
      lng: -73.27172478449964,
    },
  },
  {
    DateStr: "Saturday, June 15th",
    Date: "2024-06-15",
    Time: "11 - 6 PM",
    StartTime: "2024-06-15 11:00 AM",
    EndTime: "2024-06-15 6:00 PM",
    Title: "Father's Day Special; Bourbon, Bacon and Beer",
    Address: {
      location: "The Barley Beach House",
      street: "95 Dearborn Avenue",
      city: "Rye",
      state: "NY",
      zipcode: "10580",
    },
    Site: "https://www.villageofbrightwaters.com/home/events/17516",

    latLng: {
      lat: 40.96083639454908,
      lng: -73.68026855741655,
    },
  },
  {
    DateStr: "Saturday, June 22nd",
    Date: "2024-06-22",
    Time: "12 - 4 PM",
    StartTime: "2024-06-22 12:00 PM",
    EndTime: "2024-06-22 4:00 PM",
    Title: "Salpino 2nd Annual Summer Kickoff Event",
    Address: {
      location: "Salpino Italian Food Market & Catering",
      street: "1540 Newbridge Rd",
      city: "North Bellmore",
      state: "NY",
      zipcode: "11710",
    },
    Site: "https://www.salpino.com/",

    latLng: {
      lat: 40.6834399,
      lng: -73.5405233,
    },
  },
  {
    DateStr: "Saturday, July 12th to 14th",
    Date: "2024-07-12",
    Time: "",
    StartTime: "2024-07-12 1:00 PM",
    EndTime: "2024-07-14 4:00 PM",
    Title: "Famous Food Festival",
    Address: {
      location: "Tanger Outlets",
      street: "152 The Arches Circle",
      city: "Deer Park",
      state: "NY",
      zipcode: "11729",
    },
    Site: "https://www.eventbrite.com/e/famous-food-festival-taste-the-world-long-island-ny-summer-2024-tickets-840740787327?aff=site",
    latLng: {
      lat: 40.76323081969923,
      lng: -73.3063026516147,
    },
  },
  {
    DateStr: "Saturday, July 6th",
    Date: "2024-07-06",
    Time: "",
    StartTime: "2024-07-06",
    EndTime: "2024-07-06",
    Title: "Taste the Two Forks",
    Address: {
      location: "Southampton Arts Center",
      street: "25 Jobs Ln",
      city: "Southampton",
      state: "NY",
      zipcode: "11968",
    },
    Site: "https://danstaste.com/events/taste-of-two-forks/",
    latLng: {
      lat: 40.884359458119995,
      lng: -72.3904945780101,
    },
  },
];

export default EventsList;
