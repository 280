import * as React from "react";
import { Button, Image, Stack, Row, Col } from "react-bootstrap";
import FormGroup from "react-bootstrap/FormGroup";
import Form from "react-bootstrap/Form";
import styled from "@emotion/styled";
import { baseTheme } from "../../../styles/themes/theme";
import buildLocationArray from "../../util/buildLocationArray";
import { LocationCategories } from "../../../data/Constants/LocationCategories";
import { LocationLabels } from "../../../data/Constants/LocationLabels";
import { LocationIcons } from "../../../data/Constants/LocationIcons";

const StyledFormCheck = styled(Form.Check)(({ theme }) => ({
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1rem",
  alignItems: "center",
}));
const StyledCheckBoxDiv = styled("div")(({}) => ({
  display: "inline",
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1.3rem",
  margin: "2px 6px",
}));
const StyledCheckBoxInput = styled("input")(({}) => ({
  // display: "none",
}));
const StyledCheckBoxLabel = styled("label")(({}) => ({}));
const StyledSectionLabel = styled("div")(({}) => ({
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "1.4rem",
  alignItems: "center",
}));

const CustomCheckBox = ({ label, type, checked, onChange }) => {
  return (
    <StyledCheckBoxDiv>
      <StyledCheckBoxInput
        checked={checked}
        type="checkbox"
        name=""
        onChange={onChange}
      />
      <StyledCheckBoxLabel>{label}</StyledCheckBoxLabel>
    </StyledCheckBoxDiv>
  );
};

export const FilterButtons = ({ categories, setCategories }) => {
  const data = buildLocationArray();
  const categoryCounts = {
    restaurants:
      data.filter((item) => item.category === LocationCategories.restaurants)
        .length ?? 0,
    events:
      data.filter((item) => item.category === LocationCategories.events)
        .length ?? 0,
    retailers:
      data.filter((item) => item.category === LocationCategories.retailers)
        .length ?? 0,
    iceCreamShops:
      data.filter((item) => item.category === LocationCategories.iceCreamShops)
        .length ?? 0,
  };
  function makeLabel(category, counts) {
    return (
      <Stack direction="horizontal">
        <Image
          height={20}
          width={20}
          src={LocationIcons[category]}
          //style={{ margin: "auto" }}
        />
        <div>
          {LocationLabels[category]} ({counts})
        </div>
      </Stack>
    );
  }
  return (
    <Row>
      <Col
        xs="12"
        md="auto"
        className="d-none d-sm-block"
        style={{ textAlign: "center", margin: "auto 0" }}
      >
        <StyledSectionLabel>Categories</StyledSectionLabel>
      </Col>
      <Col xs="6" md="auto">
        <StyledFormCheck
          inline
          label={makeLabel(
            LocationCategories.events,
            categoryCounts.events ?? 0
          )}
          type="checkbox"
          checked={categories.events}
          onChange={() =>
            setCategories({ ...categories, events: !categories.events })
          }
        />
      </Col>
      <Col xs="auto" md="auto">
        <StyledFormCheck
          inline
          label={makeLabel(
            LocationCategories.restaurants,
            categoryCounts.restaurants ?? 0
          )}
          type="checkbox"
          checked={categories.restaurants}
          onChange={() =>
            setCategories({
              ...categories,
              restaurants: !categories.restaurants,
            })
          }
        />
      </Col>
      <Col xs="6" md="auto">
        <StyledFormCheck
          inline
          label={makeLabel(
            LocationCategories.retailers,
            categoryCounts.retailers ?? 0
          )}
          type="checkbox"
          checked={categories.retailers}
          onChange={() =>
            setCategories({ ...categories, retailers: !categories.retailers })
          }
        />
      </Col>
      <Col xs="auto" md="auto">
        <StyledFormCheck
          inline
          label={makeLabel(
            LocationCategories.iceCreamShops,
            categoryCounts.iceCreamShops ?? 0
          )}
          type="checkbox"
          checked={categories.iceCreamShops}
          onChange={() =>
            setCategories({
              ...categories,
              iceCreamShops: !categories.iceCreamShops,
            })
          }
        />
      </Col>
    </Row>
  );
};

export default FilterButtons;
