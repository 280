import React from "react";
import styled from "@emotion/styled";
import { baseTheme } from "../../../../styles/themes/theme";
import { Row, Col } from "react-bootstrap";
import { MdLocationOn as AddressIcon } from "react-icons/md";

const LocationAddress = styled("div")(({ textAlign }) => ({
  textAlign: textAlign ?? baseTheme.typography.infoWindow.detail.textAlign,
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "1rem",

  margin: baseTheme.typography.infoWindow.detail.margin,
  noWrap: "true",
  textOverflow: "ellipsis",
}));

export const AddressDisplay = ({
  address,
  textAlign,
  singleLine,
  className,
}) => {
  let rowStyle = textAlign === "center" ? { justifyContent: "center" } : {};
  return (
    <Row style={rowStyle} className={className}>
      {/* <Col xs={1} style={{ alignContent: "center" }}>
        <AddressIcon />
      </Col> */}
      <Col xs={"auto"} style={{ justifyContent: "center" }}>
        <Row>
          <LocationAddress textAlign={textAlign}>
            {address.street} {!singleLine && <br />}
            {address.city}, {address.state}, {address.zipCode}
          </LocationAddress>
        </Row>
      </Col>
    </Row>
  );
};

export default AddressDisplay;
