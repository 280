import * as React from "react";
import logo from "./logo.svg";
// import { ThemeProvider } from '@emotion/react';
import { ThemeProvider } from "react-bootstrap";
import { baseTheme } from "./styles/themes/theme";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/home";
import Layout from "./components/layouts/layout";
import FindUsPage from "./pages/findUs";
import FindUsPage2 from "./pages/findUs2";
import ContactPage from "./pages/contactUs";
import AboutPage from "./pages/about";
import FlavorsPage from "./pages/flavors";
import "react-calendar/dist/Calendar.css";
import "./styles/Calendar.css";
import AgeVerifcationContext from "./data/AgeContext";
import AgeVerificationModal from "./components/features/Modal";
import ScrollToTop from "./components/features/ScrollToTop";
import ReactGA from "react-ga4";
import NotificationModal from "./components/features/Modals/DucksModal";

import Recipes from "./pages/recipes";

function App() {
  const ageContext = React.useContext(AgeVerifcationContext);
  const [ageVerification, setAgeVerification] = React.useState(false);

  ReactGA.initialize("G-X4NBL5HQM9");
  return (
    <ThemeProvider theme={baseTheme}>
      <AgeVerifcationContext.Provider
        value={{
          verified: ageVerification,
          setAgeVerification: setAgeVerification,
        }}
      >
        <AgeVerificationModal />

        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomePage />} />
              <Route path="About" element={<AboutPage />} />
              <Route path="OurFlavors" element={<FlavorsPage />} />
              <Route path="FindUs" element={<FindUsPage />} />
              <Route path="FindUs_version_2" element={<FindUsPage2 />} />
              <Route path="ContactUs" element={<ContactPage />} />
              <Route path="Recipes" element={<Recipes />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </AgeVerifcationContext.Provider>
    </ThemeProvider>
  );
}

export default App;
