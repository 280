import * as React from "react";
import { createRoot } from "react-dom/client";
// import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {
  GoogleMap,
  Marker,
  LoadScript,
  InfoWindow,
  useGoogleMap,
  useLoadScript,
} from "@react-google-maps/api";
import { Container, Spinner } from "react-bootstrap";
import { mapStlye } from "../../styles/map/mapStyle";
import Button from "react-bootstrap/Button";
import RestaurantList from "../../data/Resturants.js";
import RetailersList from "../../data/Retailers";
import EventsList from "../../data/Events";
import BlueBadge from "../../images/Badge_Logo_Blue.png";
import BlackBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Black.png";
import WhiteBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_White.png";
import TanBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Tan.png";
import InfoWindowContent from "./InfoWindowContent";
import mapStyle from "../../styles/mapStyle";
import { useContext } from "react";
import FindUsContext from "../../data/Contexts/FindUsContext";
import { matchPath } from "react-router-dom";

// import InfoWindow from "./InfoWindow";
/*

TODO: Crazy Map Stuff phase2
  MAP
    - Change map based on accordion (selection) DONE
    -  Move based on selected event
    -  search based on accoridion
*/

const SwizzledMapComponent = ({
  onClick,
  onIdle,
  children,
  style,
  selectedMarkerKey,
  // selectedMakerChangeCallBack,
  ...options
}) => {
  const ref = React.createRef();

  const [map, setMap] = React.useState();

  const [openInfoWindow, setOpenInfoWindow] = React.useState(false);
  const selectedFindusContext = React.useContext(FindUsContext);
  const selectedMarker = selectedFindusContext.selectedMarker;
  const markers = selectedFindusContext.markerArray;

  function handleClose() {
    console.log("InfoWindowClosed");
    setOpenInfoWindow(false);
    if (map) {
      map.setZoom(8);
    }
    selectedFindusContext.setSelectedMarker(null);
  }
  React.useEffect(() => {
    console.log("MarkersChanged - here");
    setOpenInfoWindow(false);
    selectedFindusContext.setSelectedMarker(null);
    if (map) {
      map.setZoom(8);
    }
  }, [markers]);

  React.useEffect(() => {
    console.log("SelectedKey - In Map component");
    if (!selectedMarker) return;
    console.log(selectedMarker);
    console.log(map);

    let marker = SimMarkerClick(selectedMarker, map, ref);
    setOpenInfoWindow(true);
    if (map) {
      map.panTo(marker.props.position);
      map.setZoom(16);
    }
    console.log("*******************************");
  }, [selectedMarker]);

  return (
    <>
      <GoogleMap
        mapContainerStyle={{ height: "600px", width: "100%" }}
        center={{ lat: 40.7128, lng: -73.1006 }}
        zoom={8}
        onLoad={(val) => setMap(val)}
        options={mapStyle}
      >
        {children}
        {selectedMarker && openInfoWindow && (
          <InfoWindow
            position={selectedMarker.props.position}
            onCloseClick={() => handleClose()}
          >
            <InfoWindowContent marker={selectedMarker} />
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
  // [END maps_react_map_component_return]
};

const SimMarkerClick = (marker, mapRef, ref) => {
  if (marker === null || marker === undefined) return;

  console.log("RES:");
  console.log(marker);
  console.log(mapRef);

  if (mapRef) {
    const map = mapRef;
    if (map && marker.props.position) {
      const event = {
        latLng: new window.google.maps.LatLng(
          marker.props.position.lat,
          marker.props.position.lng
        ),
      };
      console.log(ref.current);
      map.panTo(event.latLng);
      map.setZoom(16);
    }
  }
  return marker;
};
const libraries = ["places"];
const SwizzledMap = ({ selectedKey, isLoadedParent }) => {
  const [selectedMarkerKey, setSelectedMarkerKey] = React.useState();

  const selectedLocationContext = useContext(FindUsContext);
  const selectedMarker = selectedLocationContext.selectedMarker;
  const markers = selectedLocationContext.markerArray;
  const categories = selectedLocationContext.categories;

  let google = window.google;
  let position;
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: "AIzaSyB4oGcmRiH_SagxqDTXGPKIk5XmfeKLnyE",
  // });
  function findMarker() {
    if (!markers || !selectedMarkerKey) {
      return null;
    }
    let found = markers.filter(
      (x) => x.key.toString() === selectedMarkerKey.toString()
    )[0];
    return found;
  }

  React.useEffect(() => {
    if (!selectedMarker) {
      setSelectedMarkerKey(null);
    }
  }, [selectedMarker]);

  React.useEffect(() => {
    selectedLocationContext.setSelectedMarker(findMarker());
  }, [selectedMarkerKey]);

  const onClick = (e) => {
    console.log("Marker On Click Function");
    console.log(e);
    setSelectedMarkerKey(e);
  };

  const renderMarkers = () => {
    if (!google) {
      return [];
    }

    const markersArr = [];
    const eventMarkers = [];
    const retailerMarkers = [];
    const restaurantMarkers = [];
    let gLatLng = null;
    if (categories.events) {
      EventsList.forEach((event, index) => {
        position = event.latLng;
        gLatLng = new google.maps.LatLng(position);
        eventMarkers.push(
          <Marker
            icon={{
              url: TanBadge,
              scaledSize: new google.maps.Size(30, 30),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(15, 15),
            }}
            key={"events-" + index}
            position={position}
            details={event}
            gLatLng={gLatLng}
            onClick={() => onClick("events-" + index)}
            //clickedCallback={() => onClick("events-" + index)}
            type="event"
            ref={(ref) => (event.ref = ref)}
          />
        );
      });
      markersArr.push(...eventMarkers);
    }

    if (categories.restaurants) {
      RestaurantList.forEach((restaurant, index) => {
        position = restaurant.latLng;
        gLatLng = new google.maps.LatLng(position);
        restaurantMarkers.push(
          <Marker
            icon={{
              url: BlackBadge,
              scaledSize: new google.maps.Size(30, 30),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(15, 15),
            }}
            key={"rest-" + index}
            position={position}
            details={restaurant}
            gLatLng={gLatLng}
            type="restaurant"
            onClick={() => onClick("rest-" + index)}
            ref={(ref) => (restaurant.ref = ref)}
          />
        );
      });
      markersArr.push(...restaurantMarkers);
    }
    if (categories.retailers) {
      RetailersList.forEach((retailer, index) => {
        if (retailer.multipleLocations) {
          retailer.locations.forEach((location, index2) => {
            position = location.latLng;
            gLatLng = new google.maps.LatLng(position);
            retailerMarkers.push(
              <Marker
                icon={{
                  url: BlueBadge,
                  scaledSize: new google.maps.Size(30, 30),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(15, 15),
                }}
                key={"retail-" + index + "-" + index2}
                position={position}
                details={location}
                gLatLng={gLatLng}
                onClick={() => onClick("retail-" + index + "-" + index2)}
                title={retailer.name}
                type="retailer"
              />
            );
          });
        } else {
          position = retailer.latLng;
          gLatLng = new google.maps.LatLng(position);
          retailerMarkers.push(
            <Marker
              icon={{
                url: BlueBadge,
                scaledSize: new google.maps.Size(30, 30),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(15, 15),
              }}
              key={"retail-" + index}
              position={position}
              gLatLng={gLatLng}
              onClick={() => onClick("retail-" + index)}
              details={retailer}
              type={"retailer"}
            />
          );
        }
      });
      markersArr.push(...retailerMarkers);
    }
    return markersArr;
  };

  React.useEffect(() => {
    // console.log("Cat change");
    // console.log(categories);
    selectedLocationContext.setMarkerArray(renderMarkers({ ...categories }));
  }, [categories, google]);

  return (
    <>
      {isLoadedParent && (
        <SwizzledMapComponent selectedMarkerKey={selectedKey}>
          {markers}
        </SwizzledMapComponent>
      )}
      {/* </LoadScript> */}
    </>
  );
};

export default SwizzledMap;
