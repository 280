import * as React from "react";
import { Slide } from "react-slideshow-image";
import SwizzledHero from "../../images/Swizzled_Hero.png";
import SwizzledHero2 from "../../images/homepage/SwizzledHero.png";
import "react-slideshow-image/dist/styles.css";
import styled from "@emotion/styled";
import {
  Carousel,
  CarouselItem,
  Col,
  Container,
  Image,
  Ratio,
  Row,
} from "react-bootstrap";
import CocktailsImg from "../../images/CHALKED IMAGES/chalked cocktails 4.jpg";
import CocktailsImgEdited from "../../images/CHALKED IMAGES/edited/ cocktails1x1.png";
import IceCreamImg from "../../images/CHALKED IMAGES/chalked ice cream.jpg";
import IceCreamImgEdited from "../../images/CHALKED IMAGES/edited/chalked ice cream1x1.png";
import CombinationImg from "../../images/CHALKED IMAGES/Combination chalked.jpg";
import CombinationImgEdited from "../../images/CHALKED IMAGES/edited/Combination chalked_someEdge1x1.png";
import ChalkBoard4 from "../../images/chalkboards/empty-blackboard.jpg";
import ChalkBoard5 from "../../images/chalkboards/chalckboard edited.jpg";
import ChalkBoard6 from "../../images/chalkboards/empty-blackboard edited.jpg";
import { baseTheme } from "../../styles/themes/theme";
import SwizzledHero3 from "../../images/homepage/4x3.png";

const StyledCaptionText = styled(`h3`)(() => ({
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.BertonVoyage,
  textAlign: "center",
  "@media screen and (max-width: 768px)": {
    fontSize: "30px",
  },
  "@media screen and (min-width: 769px)": {
    fontSize: "70px",
  },
  fontWeight: "bold",
}));

const StyledEquationText = styled(`h1`)(() => ({
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.BertonVoyage,
  fontSize: "50px",
  fontWeight: `bold`,
  alignItems: "center",
  alignContent: "center",
  "@media screen and (min-width: 768px)": {
    fontSize: "100px",
  },
}));

const SlideShowContainer = styled(Container)(() => ({
  //height: '900px'
}));

const StyledIceCreamImg = styled(Image)(() => ({
  maxHeight: "200px",
  maxWidth: "200px",
  "@media screen and (max-width: 768px)": {
    marginTop: "0px",
  },
  "@media screen and (min-width: 769px)": {
    marginTop: "50px",
  },
}));

const SlideEquationComponent = () => {
  return (
    <Container fluid>
      <Row className={"align-items-center"}>
        <Col
          lg={{ span: 1, offset: 2 }}
          sm={{ span: 2, offset: 0 }}
          xs={{ span: 3, offset: 1 }}
        >
          <Ratio aspectRatio={"1x1"}>
            <StyledIceCreamImg src={IceCreamImgEdited} />
          </Ratio>
        </Col>
        <Col
          lg={{ span: 1, offset: 0 }}
          sm={{ span: 1, offset: 0 }}
          xs={{ span: 1, offset: 0 }}
        >
          <StyledEquationText className="d-flex justify-content-center">
            +
          </StyledEquationText>
        </Col>
        <Col
          lg={{ span: 2, offset: 0 }}
          sm={{ span: 3, offset: 0 }}
          xs={{ span: 5, offset: 0 }}
        >
          <Ratio aspectRatio={"1x1"}>
            <Image src={CocktailsImgEdited} />
          </Ratio>
        </Col>
        <Col
          lg={{ span: 1, offset: 0 }}
          sm={{ span: 1, offset: 0 }}
          xs={{ span: 1, offset: 0 }}
        >
          <StyledEquationText className="d-flex justify-content-center">
            =
          </StyledEquationText>
        </Col>
        <Col
          lg={{ span: 3, offset: 0 }}
          sm={{ span: 5, offset: 0 }}
          xs={{ span: 8, offset: 2 }}
        >
          <Ratio aspectRatio={"1x1"}>
            <Image src={CombinationImgEdited} />
          </Ratio>
        </Col>
      </Row>
    </Container>
  );
};

const MainSlide = () => {
  const slide = {
    image: SwizzledHero3,
    captionLine1: "Alcohol Blended Small Batch",
    captionLine2: "Ice Creams & Sorbets",
    ratio: "4x3",
  };
  return (
    <>
      <Row className={"align-content-center gx-0"} style={{ height: "600px" }}>
        <Col
          className={"align-self-center"}
          md={{ span: 6, offset: 3 }}
          lg={{ span: 8, offset: 0 }}
        >
          <StyledCaptionText>{slide.captionLine1}</StyledCaptionText>
          <StyledCaptionText>{slide.captionLine2}</StyledCaptionText>
        </Col>
        <Col lg={{ span: 4, offset: 0 }} md={{ span: 8, offset: 2 }}>
          {/* <Ratio aspectRatio={slide.ratio} > */}
          <Image fluid className={"float-end"} src={slide.image} alt="" />
          {/* </Ratio> */}
        </Col>
      </Row>
    </>
  );
};

const SlideShow = () => {
  return (
    <SlideShowContainer
      fluid
      style={{
        background: `url(${ChalkBoard4})`,
        height: "600px",
        maxHeight: "600px",
        alignContent: "center",
        padding: "50px 0",
      }}
    >
      <Row>
        <Carousel style={{ paddingTop: "50px" }}>
          <CarouselItem interval={5000}>
            <MainSlide />
          </CarouselItem>

          <CarouselItem>
            <SlideEquationComponent />
          </CarouselItem>
        </Carousel>
      </Row>
    </SlideShowContainer>
  );
};

export default SlideShow;
