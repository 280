import React from "react";
import styled from "@emotion/styled";
import { baseTheme } from "../../../../styles/themes/theme";
import { Row, Col } from "react-bootstrap";
import { FaGlobe as SiteIcon } from "react-icons/fa";

const LocationSite = styled("a")(() => ({
  fontFamily: baseTheme.typography.bodyText,
  fontSize: "0.9rem",
  noWrap: "true",
  textOverflow: "ellipsis",
  margin: baseTheme.typography.infoWindow.detail.marginValue,
}));
export const SwizzledSiteDisplay = ({
  href,
  label,
  textAlign,
  hideSiteName,
}) => {
  let rowStyle = textAlign === "center" ? { justifyContent: "center" } : {};
  return (
    <Row style={rowStyle}>
      {/* <Col xs={1} style={{ alignContent: "center" }}>
        <SiteIcon />
      </Col> */}
      <Col xs={"auto"} style={{ textAlign: textAlign ?? "left" }}>
        <LocationSite href={href} target="blank">
          Visit {label && !hideSiteName ? label : "Site"}
        </LocationSite>
      </Col>
    </Row>
  );
};
