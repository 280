import { baseTheme as theme } from "../../styles/themes/theme";
import { styled, Box, padding } from "@mui/system";
import * as React from "react";
import EqualsSign from "../../images/equations/EqualSignIcon.png";
// import IceCream from "../../images/CHALKED IMAGES/edited/justthescoop.png"
import IceCream from "../../images/equations/updated/iceCream.png";
import PlusSign from "../../images/equations/PlusSignIcon.png";
import Cocktail from "../../images/CHALKED IMAGES/edited/cocktail.png";
import Logo from "../../images/equations/Swizzled Medallion.png";
import Logo2 from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_White.png";
import PrimaryLogo from "../../images/logos/Primary_Logo.png";
import { Col, Container, Image, Ratio, Row } from "react-bootstrap";
const EQBox = styled(Row)(() => ({
  height: "250px",
  background: theme.palette.blue,
  marginTop: "5px",
  textAlign: "center",
  alignItems: "center",
  padding: "10px",
}));

const EQBoxMini = styled(`div`)(() => ({
  width: "270px",
  "@media screen and (max-width:769px)": {
    margin: "auto",
  },
  marginLeft: "50px",
  // position: 'absolute',
}));

export const EquationsBannerMini = ({}) => {
  return (
    <EQBoxMini>
      <Row className={"justify-content-center align-items-center gx-0"}>
        <Col xs={{ span: 3 }} md={{ span: 3 }}>
          {/* <Ratio aspectRatio="1x1" style={{maxHeight: '50px', maxWidth:'50px'}}> */}
          <Image
            fluid
            src={IceCream}
            style={{ maxHeight: "50px", maxWidth: "50px" }}
          />

          {/* </Ratio> */}
        </Col>
        <Col xs={{ span: 1 }} md={{ span: 1 }}>
          {/* <Ratio aspectRatio="1x1" style={{maxHeight: '15px', maxWidth:'15px', alignItems:'center'}}> */}
          <Image
            src={PlusSign}
            style={{ maxHeight: "15px", maxWidth: "15px" }}
          />
          {/* </Ratio> */}
        </Col>
        <Col xs={{ span: 3 }} md={{ span: 3 }}>
          {/* <Ratio aspectRatio="1x1" style={{maxHeight: '50px', maxWidth:'50px'}}> */}
          <Image
            src={Cocktail}
            style={{ maxHeight: "50px", maxWidth: "50px" }}
          />
          {/* </Ratio> */}
        </Col>
        <Col xs={{ span: 1 }} md={{ span: 1 }}>
          {/* <Ratio aspectRatio="1x1" style={{maxHeight: '15px', maxWidth:'15px'}}> */}
          <Image
            src={EqualsSign}
            style={{ maxHeight: "15px", maxWidth: "15px" }}
          />
          {/* </Ratio> */}
        </Col>

        <Col xs={{ span: 4 }} md={{ span: 4 }}>
          {/* <Ratio aspectRatio="1x1" style={{maxHeight: '75px', maxWidth:'75px'}}> */}
          <Image src={Logo} style={{ maxHeight: "55px", maxWidth: "55px" }} />
          {/* </Ratio> */}
        </Col>
      </Row>
    </EQBoxMini>
  );
};

const EquationsBanner = ({}) => {
  return (
    <EQBox>
      <Col xs={{ span: 2, offset: 0 }} md={{ span: 2, offset: 2 }}>
        <Ratio
          aspectRatio="1x1"
          style={{ maxHeight: "150px", maxWidth: "150px" }}
        >
          <Image fluid src={IceCream} />
        </Ratio>
      </Col>
      <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 0 }}>
        <Ratio
          aspectRatio="1x1"
          style={{ maxHeight: "50px", maxWidth: "50px" }}
        >
          <Image src={PlusSign} />
        </Ratio>
      </Col>
      <Col xs={{ span: 2, offset: 0 }} md={{ span: 2, offset: 0 }}>
        <Ratio
          aspectRatio="1x1"
          style={{ maxHeight: "150px", maxWidth: "150px" }}
        >
          <Image src={Cocktail} />
        </Ratio>
      </Col>
      <Col xs={{ span: 2, offset: 0 }} md={{ span: 1, offset: 0 }}>
        <Ratio
          aspectRatio="1x1"
          style={{ maxHeight: "50px", maxWidth: "50px" }}
        >
          <Image src={EqualsSign} />
        </Ratio>
      </Col>

      <Col xs={{ span: 4, offset: 0 }} md={{ span: 3, offset: 0 }}>
        <Ratio
          aspectRatio="1x1"
          style={{ maxHeight: "200px", maxWidth: "200px" }}
        >
          <Image src={Logo} />
        </Ratio>
      </Col>
    </EQBox>
  );
};

export default EquationsBanner;
