import { Badge, Stack } from "react-bootstrap";
import styled from "@emotion/styled";
import { baseTheme } from "../../../styles/themes/theme";

const DietaryBadge = styled(Badge)(() => ({
  "@media (maxWidth: 768px)": {},
  fontFamily: baseTheme.typography.bodyText,
  fontSize: ".8rem",
  color: baseTheme.palette.primaryWhite,
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
  backgroundColor: baseTheme.general.backgroundColor,
  border: `2.5px solid ${baseTheme.palette.primaryWhite}`,
  borderRadius: "50%",
  margin: "auto",
  justifyContent: "center",
  height: "60px",
  width: "60px",
  whiteSpace: "pre-wrap",
}));

export const DietaryBadges = ({ flavorObject, style }) => {
  return (
    <Stack
      direction="horizontal"
      gap={1}
      style={style ?? { margin: "15px auto 0 auto" }}
    >
      {flavorObject.glutenFree && (
        <DietaryBadge bg={""}>Gluten Free</DietaryBadge>
      )}

      {flavorObject.vegan && <DietaryBadge bg={""}>Vegan</DietaryBadge>}

      {flavorObject.dairyFree && (
        <DietaryBadge bg={""}>Dairy Free</DietaryBadge>
      )}
    </Stack>
  );
};

export default DietaryBadges;
