import BlueBadge from "../../images/Badge_Logo_Blue.png";
import BlackBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Black.png";
import WhiteBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_White.png";
import TanBadge from "../../images/logos/Swizzled_Final_Brand_Swizzled_Badge_Logo_Tan.png";

export const LocationIcons = {
  restaurants: BlackBadge,
  events: TanBadge,
  iceCreamShops: WhiteBadge,
  retailers: BlueBadge,
};
