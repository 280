export const fonts={
        SixHands: 'six-hands-black, sans-serif',
        Chalky: 'chalky, sans-serif',
        Berton: 'Berton',
        BertonVoyage: 'BertonVoyage',
        LeHavre: {
            regular: 'le-havre',
            rounded: 'le-havre-rounded'
        },
        Guilia:{
            plain: 'giulia-plain'
        }
} 

export default fonts;