import React, { useContext, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Stack,
  ListGroupItem,
} from "react-bootstrap";
import styled from "@emotion/styled";
import { baseTheme } from "../../../../styles/themes/theme";
import { LocationLabels } from "../../../../data/Constants/LocationLabels";
import { EventDateTimeDisplay } from "../../Markers/InfoWindows/EventDateTimeDisplay";
import { SwizzledSiteDisplay } from "../../Markers/InfoWindows/SiteDisplay";
import { AddressDisplay } from "../../Markers/InfoWindows/AddressDisplay";
import { PhoneDisplay } from "../../Markers/InfoWindows/PhoneDisplay";
import FindUsContext from "../../../../data/Contexts/FindUsContext";

const LocationTitle = styled("h4")(() => ({
  textAlign: "center",
  fontFamily: baseTheme.typography.h1Text,
  fontSize: "1.1rem",
  fontWeight: "bold",
}));

const LocationLabel = styled("p")(() => ({
  textAlign: "left",
  fontFamily: baseTheme.typography.bodyText,
  fontSize: ".8rem",
  padding: "4px",
  margin: baseTheme.typography.infoWindow.detail.margin,
}));

export const LocationListGroupItem = ({ location }) => {
  const address = location && location.address ? location.address : null;
  const context = useContext(FindUsContext);
  const { categories, selectedMarker, setSelectedMarker } = context;
  const [hidden, setHidden] = React.useState();

  React.useEffect(() => {
    setHidden(!categories[location.category]);
  }, [categories]);

  const locClicked = (itemKey) => {
    console.log("Location Clicked: ", itemKey);
    setSelectedMarker(itemKey);
  };

  return (
    <ListGroupItem
      key={location.key}
      action
      hidden={hidden}
      onClick={() => locClicked(location.key)}
      style={{
        padding: "8px 0",
      }}
    >
      <Row>
        <Col
          xs={4}
          sm={12}
          style={{ alignContent: "center", borderRight: "1px solid black" }}
        >
          <Row style={{ justifyContent: "center" }}>
            <Col xs={1} style={{ alignContent: "center" }}>
              <Image
                height={25}
                width={25}
                src={location.icon}
                style={{ margin: "auto" }}
                rounded
              />
            </Col>
            <Col
              xs="auto"
              sm="auto"
              style={{ alignContent: "center", textAlign: "center" }}
            >
              <LocationLabel>{LocationLabels[location.category]}</LocationLabel>
            </Col>
          </Row>

          {location.eventDetails.startTime && (
            <EventDateTimeDisplay
              textAlign={"center"}
              startTime={location.eventDetails.startTime}
              endTime={location.eventDetails.endTime}
            />
          )}
        </Col>
        <Col>
          <Row>
            <Col>
              <LocationTitle>{location.name}</LocationTitle>
            </Col>
          </Row>
          <AddressDisplay textAlign={"center"} singleLine address={address} />

          {location.phoneNumber && (
            <PhoneDisplay
              className="d-none d-sm-block"
              textAlign={"center"}
              phoneNumber={location.phoneNumber}
            />
          )}
          {location.site && (
            <SwizzledSiteDisplay
              className="d-none d-sm-block"
              textAlign={"center"}
              href={location.site}
              label={location?.name}
            />
          )}
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default LocationListGroupItem;
