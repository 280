import * as React from 'react';
import { Modal, Button} from 'react-bootstrap';
import AgeVerifcationContext from '../../data/AgeContext';


function getCookie(cname){
    let name=cname +"=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
     for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
    }
      return "";
}


const AgeVerificationModal = () =>{
    // let ageCookie = "verified=false; path=/;";
    // console.log(getCookie("verified"));
    let ageContext = React.useContext(AgeVerifcationContext);

    //const {ageVerified, setAgeVerified} = React.useContext(AgeVerifcationContext);
    const [show, setShow] = React.useState(!ageContext.verified);
    
function handleYesClicked(){
    console.log(ageContext);
    ageContext.setAgeVerification(true);
    setShow(false);
}
function handleClose(){
    setShow(false);
}

    return(
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>Ready to Get Swizzled? </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you at or above legal drinking age?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary">Not Yet</Button>
                    <Button variant="primary" onClick={handleYesClicked}>Of Course</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AgeVerificationModal;