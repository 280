import { Col, Container } from "react-bootstrap";
import { StyledCol, StyledRow } from "../components/styled/styledRowsCols";
import styled from "@emotion/styled";
import StyledParagraph from "../components/styled/styledText";
import Image from "react-bootstrap/Image";
import HomeMadeIceCream from "../images/aboutpage/HOME MADE ICE CREAM SHARPENED.jpg";
import SwizzledBeingMade from "../images/aboutpage/SwizzledBeingMade.JPG";
import Truck from "../images/aboutpage/Graphics.jpg";
import { baseTheme } from "../styles/themes/theme";
import Hero2 from "../components/features/Hero2";

const Signature = styled(`p`)(() => ({
  color: baseTheme.palette.primaryWhite,
  fontFamily: baseTheme.typography.h1Text,
  "@media screen and (max-width:769px)": {
    fontSize: "2.5rem",
  },
  "@media screen and (min-width: 770px) and (max-width:1200px)": {
    fontSize: "3rem",
  },
  fontSize: "4rem",
}));

const aboutTxt = [
  "Have you ever been around someone who you just know is going to turn a dream into reality? That’s my dad!",
  "Dad, (Steve to you guys) used to joke around with his coworkers that one day he was going to just buy an ice cream truck, start his own brand and have fun. And no surprise to us, he was actually serious. Now, we are introducing Swizzled to the world!",
  "But let’s rewind a little bit. After this ongoing joke of wanting to buy an ice cream truck, a good friend (thanks Joe!) suggested, why not create a line of “boozy” ice cream? After some long nights of brewing (pun intended) ideas in his mind, he figured that you can’t have more fun than combining 2 of his favorite things. Liquor and ice cream.",
  "But Dad wanted to make this a family affair. So my brother Ryan and I, (I’m Karli) 2 gullible twenty-somethings, figured…Yeah!…why not? Let’s make alcoholic ice cream with Dad. Next thing we knew, after receiving packages upon packages of ice cream makers, mixing bowls, measuring cups, a giant freezer and loads of fresh and delicious ingredients, our basement was turned into a creamery virtually overnight…oh, and did I mention cases of alcohol! ",
  "Then, with a lot of research and long, late nights of mixing and experimenting, we concocted some awesome flavor combinations. We worked to find the perfect ratios of alcohol to ice cream to ingredients specific to each flavor. (Big props to Mom for being the official taste critic...and she claims that she hasn’t gained a pound!)",
  "But wait. Have I also mentioned that I’m lactose intolerant? So how ironic would it be for me to be an owner of an ice cream company and not be able to enjoy what we make? So in order for myself, and others with dietary challenges like me, to enjoy our delicious alcoholic desserts, we added sorbets and dairy-free bases to the line. Don’t worry my celiac suffering friends, we’ve got gluten-free flavors and vegan options too!",
  "Once satisfied with the first round of flavors, we then found the perfect partner in a N.Y. based, family owned manufacturer to make our small batch recipes with the close oversight we expect. After a few failed runs, and multiple adjustments to the recipes, we created our first 10 flavors.",
  "Next was to figure out how to deliver our products to retail freezer shelves, food establishments and to special events. Our first delivery van hit the road in February, 2023 and we personally deliver all product. But Steve, being a car guy, has something special coming soon. Watch for our custom, antique ice cream truck to hit the road!",
  "So that's basically an abridged and honest version of how we got started. We are certainly not the first or the only company to make alcohol blended ice creams, but we really think we offer the best! We're not trying to save the planet, cure infectious diseases or rebuild fallen economies. We just want to make people smile.....while getting buzzed during a brain freeze!",
  "We hope you all enjoy our frozen desserts just as much as we enjoy serving them. If so, please tell a friend…or twenty!",
  "Thanks for visiting our website and please remember to dessert responsibly!",
  "-Karli",
];

const Divider = styled("hr")(() => ({
  margin: "1rem 0",
  border: 0,
}));

const AboutTextSection = () => {
  const section1 = aboutTxt.slice(0, 4);
  const section2 = aboutTxt.slice(4, 8);
  const section3 = aboutTxt.slice(8, 11);
  const signature = aboutTxt[11];

  //Make images smaller

  return (
    <Container className="clearfix">
      <Divider />
      <StyledRow>
        <StyledCol sm={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
          {section1.map((paragraph, index) => (
            <StyledParagraph text={paragraph} />
          ))}
        </StyledCol>
        <StyledCol sm={{ span: 12, order: 1 }} md={4}>
          <Image src={HomeMadeIceCream} fluid />
        </StyledCol>
      </StyledRow>
      {/* <Divider /> */}
      <StyledRow>
        <StyledCol sm={12} md={4}>
          <Image src={SwizzledBeingMade} fluid />
        </StyledCol>
        <StyledCol sm={12} md={7}>
          {section2.map((paragraph, index) => (
            <StyledParagraph text={paragraph} />
          ))}
        </StyledCol>
      </StyledRow>
      {/* <Divider /> */}
      <StyledRow>
        <StyledCol sm={{ span: 12, order: 2 }} md={{ span: 7, order: 1 }}>
          {section3.map((paragraph, index) => (
            <StyledParagraph text={paragraph} />
          ))}
        </StyledCol>
        <StyledCol sm={{ span: 12, order: 1 }} md={5}>
          <Image src={Truck} fluid />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol smd={{ span: 12 }}>
          <Signature>{signature}</Signature>
        </StyledCol>
      </StyledRow>
    </Container>
  );
};

const AboutTextSection2 = () => {
  const section1 = aboutTxt.slice(0, 4);
  const section2 = aboutTxt.slice(4, 8);
  const section3 = aboutTxt.slice(8, 11);
  const signature = aboutTxt[11];
  return (
    <Container className={"clearfix"}>
      <Col className="float-md-end" sm={{ span: 12, order: 1 }} md={5}>
        <Image src={HomeMadeIceCream} fluid style={{ padding: 25 }} />
      </Col>

      {section1.map((paragraph, index) => (
        <StyledParagraph text={paragraph} />
      ))}

      {}

      <Col className="float-md-start" sm={{ span: 12, order: 1 }} md={5}>
        <Image src={SwizzledBeingMade} fluid style={{ padding: 25 }} />
      </Col>

      {section2.map((paragraph, index) => (
        <StyledParagraph text={paragraph} />
      ))}
      <Col className="float-md-end" sm={{ span: 12, order: 1 }} md={5}>
        <Image src={Truck} fluid style={{ padding: 25 }} />
      </Col>
      {section3.map((paragraph, index) => (
        <StyledParagraph text={paragraph} />
      ))}
      <StyledCol smd={{ span: 12 }}>
        <Signature>{signature}</Signature>
      </StyledCol>
    </Container>
  );
};

const heroImgStyle = { height: "530px", marginTop: -20 };

const heroColumnBreakpoints = {
  xs: { span: 6, offset: 3 },
  xl: { span: 7, offset: 2 },
};
const heroImageColumnBreakpoints = {
  xs: { span: 12, offset: 0 },
  xl: { span: 3, offset: 0 },
};

export const AboutPage = () => {
  return (
    <>
      {/* <Hero heroText={"About Swizzled"} heroImage={HeroImg2} heroImageStyle={heroImgStyle} 
    heroColumnBreakpoints= {heroColumnBreakpoints}
    heroImageColumnBreakpoints={heroImageColumnBreakpoints}/> */}
      <Hero2 heroText="About Swizzled" />
      <Container>
        <AboutTextSection2 />
        {/* <Container>
          <Col
            xl={{ span: 8, offset: 2 }}
            style={{ marginTop: 10, marginBottom: 100 }}
          >
            <Ratio aspectRatio={"16x9"}>
              <iframe
                src="https://www.youtube.com/embed/YJK2fnb3hLA"
                title="Ask The Locals - Swizzled Desserts"
              />
            </Ratio>
          </Col>
        </Container> */}
      </Container>
    </>
  );
};

export default AboutPage;
