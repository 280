import Header from "../components/layouts/header";
import SlideShow from "../components/features/Slideshow";
import EquationsBanner from "../components/features/EquationBanner";
// import MissionBanner from "../components/features/MissionBanner";
// import FlavorsBanner from "../components/features/FlavorsBanner";
// import FindUsBanner from "../components/features/FindUsBanner";
import { Container } from "@mui/system";
import Banner, {
  BannerwithImage,
  BannerwithImageFirst,
} from "../components/features/Banner";
import coupleFeedingEachother from "../images/coupleFeedingEachother.jpg";
import TruckPhoto from "../images/Truck Blurred Background.jpg";
import LidsCropped from "../images/LIDS Cropped.jpg";
import LidsCropped2 from "../images/homepage/Lids image.jpg";
import styled from "@emotion/styled";
import AgeVerificationModal from "../components/features/Modal";
import CroppedSpoon from "../images/Cropped Spoonfulls Original.jpg";
import { HomePageHero } from "../components/features/Hero2";

//TODO:Add video to home page

const missionStatement =
  "Our initial goal was to create an assortment of unique flavors to satisfy every discerning palette while including dairy free, gluten free and vegan options. We experimented with quite a few product runs in search of the perfect blends of flavor and alcohol combinations. Many friends, family….and we must admit, a few strangers, encompassing several generations were included in taste tests. After getting lots of feedback and taking many suggestions into account, we believe our first 10 releases will delight the masses, no matter your sweet tooth preference.";
const missionImg = coupleFeedingEachother;

const flavorsStatement =
  "We produce our ice creams and sorbets in small quantities to maintain a high level of control over the process. New flavors will be introduced as the seasons change and holidays call for special themes. Some may sound familiar, but we guarantee a different twist on each iteration. We invite you to get Swizzled, but please remember to dessert responsibly!";
const flavorsImg = LidsCropped2;

const findUsText =
  "Look for Swizzled at local retailers such as specialty food and beverage stores across Long Island. Swizzled will also be served at discerning restaurants and eateries as well. Additionally, follow us on social media and our website to find out about pop up locations and events throughout the year. You can find all our partners using the interactive map on the Find Us page as well as a SPECIAL OFFER to help us reach a swizzled retailer near you.";
const findUsImg = TruckPhoto;

const Divider = styled("hr")(() => ({
  margin: "3rem 0",
}));

const MissionBanner = () => {
  return (
    <BannerwithImage
      bannerText={missionStatement}
      bannerTitle="Our Mission"
      bannerImg={missionImg}
      bannerLink="About"
      bannerLinkText="About Us"
    />
  );
};

const FlavorsBanner = () => {
  return (
    <BannerwithImageFirst
      bannerText={flavorsStatement}
      bannerTitle="Our Flavors"
      bannerImg={CroppedSpoon}
      bannerLink="OurFlavors"
      bannerLinkText="Our Flavors"
    />
  );
};

const FindUsBanner = () => {
  return (
    <BannerwithImage
      bannerText={findUsText}
      bannerTitle="Find Us"
      bannerImg={flavorsImg}
      bannerLink="FindUs"
      bannerLinkText="Find Us"
    />
  );
};

export const HomePage = () => {
  return (
    <>
      <HomePageHero
        heroText={"Alcohol Blended Small Batch"}
        heroText2={"Ice Creams & Sorbets"}
      />
      <Container>
        <MissionBanner />
        <Divider />
        <FlavorsBanner />
        <Divider />
        <FindUsBanner />
        <Divider style={{ border: 0 }} />
      </Container>
    </>
  );
};

export default HomePage;
